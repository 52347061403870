define("applicant-cli/components/v-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="panel panel-default jist">
    <div class="panel-heading h3 no-margin">
      {{!-- Allow triple-curlies for html content from api --}}
      {{!-- template-lint-disable no-triple-curlies --}}
      {{{@model.text}}}
    </div>
  
    <ul class="list-group">
      {{#each @model.answers as |answer|}}
        <li class="list-group-item pointer unselectable {{if (eq answer this.curAnswer) "active"}}" role="button" {{action "pick" answer}}>
          {{!-- Allow triple-curlies for html content from api --}}
          {{!-- template-lint-disable no-triple-curlies --}}
          {{{answer.text}}}
        </li>
      {{/each}}
    </ul>
  </div>
  
  <XButton @class="btn btn-primary btn-block" @isLoading={{this.isLoading}} @isDisabled={{not this.curAnswer}} @click={{action "submit"}}>
    Continue
  </XButton>
  
  */
  {"id":"e2KoS6AB","block":"{\"symbols\":[\"answer\",\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"panel panel-default jist\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"panel-heading h3 no-margin\"],[12],[2,\"\\n\"],[2,\"    \"],[2,[32,2,[\"text\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"ul\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,2,[\"answers\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"li\"],[16,0,[31,[\"list-group-item pointer unselectable \",[30,[36,1],[[30,[36,0],[[32,1],[32,0,[\"curAnswer\"]]],null],\"active\"],null]]]],[24,\"role\",\"button\"],[4,[38,2],[[32,0],\"pick\",[32,1]],null],[12],[2,\"\\n\"],[2,\"        \"],[2,[32,1,[\"text\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"x-button\",[],[[\"@class\",\"@isLoading\",\"@isDisabled\",\"@click\"],[\"btn btn-primary btn-block\",[32,0,[\"isLoading\"]],[30,[36,5],[[32,0,[\"curAnswer\"]]],null],[30,[36,2],[[32,0],\"submit\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  Continue\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"action\",\"-track-array\",\"each\",\"not\"]}","meta":{"moduleName":"applicant-cli/components/v-question.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    curAnswer: null,
    isLoading: false,
    start: null,

    didReceiveAttrs() {
      this._super(...arguments);

      this.setProperties({
        curAnswer: null,
        isLoading: false,
        start: new Date()
      });
    },

    actions: {
      pick(answer) {
        if (Ember.isEqual(answer, this.curAnswer)) {
          this.send('submit');
        } else {
          this.set('curAnswer', answer);
        }
      },

      submit() {
        let duration = (new Date() - this.start) / 1000;
        let response = {
          questionId: this.model.id,
          answerId: this.curAnswer.id,
          duration: Math.round(duration)
        }; // Ensure there's a response (if disabled doesn't work)

        if (Ember.isNone(response.answerId)) {
          return;
        }

        this.set('isLoading', true);
        this.continue(response);
      }

    }
  }));

  _exports.default = _default;
});