define("applicant-cli/adapters/survey", ["exports", "applicant-cli/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SurveyAdapter extends _application.default {
    urlForFindRecord(id, modelName, snapshot) {
      let url = super.urlForFindRecord(...arguments);
      let {
        ajoId
      } = snapshot.adapterOptions.query;
      return url.replace('/surveys/', "/apply/".concat(ajoId, "/surveys/"));
    }

  }

  _exports.default = SurveyAdapter;
});