define("applicant-cli/components/x-pdf", ["exports", "x-pdf/components/x-pdf"], function (_exports, _xPdf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _xPdf.default;
    }
  });
});