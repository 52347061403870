define("applicant-cli/templates/apply/survey/question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wgDydQ8d",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"v-question\",[],[[\"@model\",\"@continue\"],[[32,1],[30,[36,0],[\"advanceQuestion\"],null]]],null],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "applicant-cli/templates/apply/survey/question.hbs"
    }
  });

  _exports.default = _default;
});