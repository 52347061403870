define("applicant-cli/transforms/array", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ArrayTransform extends _transform.default {
    deserialize(serialized) {
      if (Array.isArray(serialized)) {
        return serialized;
      } else {
        return [];
      }
    }

    serialize(deserialized) {
      if (Array.isArray(deserialized)) {
        return deserialized;
      } else {
        return [];
      }
    }

  }

  _exports.default = ArrayTransform;
});