define("applicant-cli/templates/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rq2kymbV",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"isLoggedIn\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"page-help\",[],[[\"@model\"],[[32,0,[\"model\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"help\",[],[[\"@option\"],[[32,0,[\"option\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "applicant-cli/templates/help.hbs"
    }
  });

  _exports.default = _default;
});