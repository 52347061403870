define("applicant-cli/components/v-message", ["exports", "applicant-cli/utils/enum"], function (_exports, _enum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="panel-heading pointer" data-target="#m-{{@model.id}}" data-toggle="collapse" data-parent="#a-msg">
    <h4 class="panel-title">
      <VAvatar
        @class="pull-left"
        @name={{@model.sender.name}}
        @userId={{@model.sender.id}}
        @version={{@model.sender.avatar}}
      />
  
      <i class="fa fa-chevron-right" aria-hidden="true" />
      {{@model.subject}}
  
      <span class="pull-right">
        {{moment-format @model.sent "LL"}}
      </span>
    </h4>
  </div>
  
  <div id="m-{{@model.id}}" class="panel-collapse collapse" role="tabpanel">
    <div class="panel-body">
      <pre class={{unless @model.ajoId "bottom0"}}>
        {{~@model.body~}}
      </pre>
  
      {{#if (and @model.ajoId this.isInvite)}}
        <LinkTo @route="apply" @model={{model.ajoId}} @tagName="btn" class="btn btn-default">
          Apply
        </LinkTo>
      {{/if}}
    </div>
  </div>
  
  */
  {"id":"cDR6uMk2","block":"{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"panel-heading pointer\"],[15,\"data-target\",[31,[\"#m-\",[32,1,[\"id\"]]]]],[14,\"data-toggle\",\"collapse\"],[14,\"data-parent\",\"#a-msg\"],[12],[2,\"\\n  \"],[10,\"h4\"],[14,0,\"panel-title\"],[12],[2,\"\\n    \"],[8,\"v-avatar\",[],[[\"@class\",\"@name\",\"@userId\",\"@version\"],[\"pull-left\",[32,1,[\"sender\",\"name\"]],[32,1,[\"sender\",\"id\"]],[32,1,[\"sender\",\"avatar\"]]]],null],[2,\"\\n\\n    \"],[10,\"i\"],[14,0,\"fa fa-chevron-right\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n    \"],[1,[32,1,[\"subject\"]]],[2,\"\\n\\n    \"],[10,\"span\"],[14,0,\"pull-right\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[32,1,[\"sent\"]],\"LL\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[15,1,[31,[\"m-\",[32,1,[\"id\"]]]]],[14,0,\"panel-collapse collapse\"],[14,\"role\",\"tabpanel\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n    \"],[10,\"pre\"],[15,0,[30,[36,2],[[32,1,[\"ajoId\"]],\"bottom0\"],null]],[12],[1,[32,1,[\"body\"]]],[13],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[32,1,[\"ajoId\"]],[32,0,[\"isInvite\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"link-to\",[[24,0,\"btn btn-default\"]],[[\"@route\",\"@model\",\"@tagName\"],[\"apply\",[34,0,[\"ajoId\"]],\"btn\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Apply\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"moment-format\",\"unless\",\"and\",\"if\"]}","meta":{"moduleName":"applicant-cli/components/v-message.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['panel', 'panel-default', 'messages'],
    isInvite: Ember.computed.equal('model.type', _enum.MessageType.Invite)
  }));

  _exports.default = _default;
});