define("applicant-cli/templates/apply/hartman/phase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ACGzBKC1",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"h1\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"\\n\"],[2,\"  \"],[2,[32,1,[\"instruction\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"v-hartman\",[],[[\"@model\",\"@phase\",\"@update\"],[[32,1,[\"hartman\"]],[32,0,[\"curPhase\"]],[30,[36,0],[[32,0],\"update\"],null]]],null],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"error\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"alert alert-danger\"],[14,\"role\",\"alert\"],[12],[2,\"\\n    \"],[1,[32,0,[\"error\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"x-button\",[],[[\"@class\",\"@isLoading\",\"@click\"],[\"btn btn-primary btn-block\",[32,0,[\"isLoading\"]],[30,[36,0],[[32,0],\"next\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  Continue\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "applicant-cli/templates/apply/hartman/phase.hbs"
    }
  });

  _exports.default = _default;
});