define("applicant-cli/helpers/link-webservice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function linkWebservice() {
    let {
      hostname,
      protocol
    } = location; // http://bj.devs
    // if (hostname.endsWith('.devs')) {

    if (hostname.endsWith('.devs')) {
      return 'https://webservices-master.hcsqa.com';
    } // https://grace-burebista.hcsqa.com
    // if (hostname.endsWith('hcsqa.com')) {


    if (hostname.endsWith('hcsqa.com')) {
      let [subdomain] = hostname.split('-');
      return "".concat(protocol, "//").concat(hostname.replace(subdomain, 'webservices'));
    } // if (hostname.endsWith('hcshiring.com') || hostname.endsWith('vikus.net')) {


    if (hostname.endsWith('hcshiring.com') || hostname.endsWith('vikus.net')) {
      return 'https://webservices.hcshiring.com';
    }
  }

  var _default = Ember.Helper.helper(linkWebservice);

  _exports.default = _default;
});