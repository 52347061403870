define("applicant-cli/templates/onboarding/end", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "afIV4Gnw",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"h2 text-center\"],[12],[2,\"\\n  Thank you for completing onboarding!\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"\\n  \"],[1,[32,0,[\"model\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "applicant-cli/templates/onboarding/end.hbs"
    }
  });

  _exports.default = _default;
});