define("applicant-cli/routes/apply/survey", ["exports", "applicant-cli/utils/ajax", "applicant-cli/utils/enum", "applicant-cli/utils/scrollPage"], function (_exports, _ajax, _enum, _scrollPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApplySurveyRoute = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = (_temp = class ApplySurveyRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "titleToken", 'Survey');

      _initializerDefineProperty(this, "user", _descriptor, this);
    }

    beforeModel() {
      let apply = this.modelFor('apply');
      let pageNav = apply.pageNavs.find(x => x.type === _enum.PageType.Jist);

      if (pageNav.isComplete) {
        this.send('advance', pageNav);
      } else {
        // Update active `pageNav`
        this.user.set('app.pageId', pageNav.id);
      }
    }

    model(params) {
      let {
        ajoId
      } = this.paramsFor('apply');
      let adapterOptions = {
        query: {
          ajoId
        }
      };
      return this.store.findRecord('survey', params.interviewId, {
        adapterOptions
      });
    }

    afterModel() {
      super.afterModel(...arguments);
      (0, _scrollPage.default)();
    }

    setupController(controller) {
      super.setupController(...arguments);
      controller.set('questionId', null);
    }

    advanceQuestion(answer) {
      let {
        questions
      } = this.modelFor(this.routeName);
      let {
        current
      } = this.controllerFor('apply.survey');
      let isLast = questions.length === current + 1;

      if (Ember.isNone(answer)) {
        return this.advanceQuestionPage(questions[current]);
      }

      if (isLast) {
        answer.lastQuestion = true;
      }

      answer.ajoId = this.paramsFor('apply').ajoId;
      let url = "answers/".concat(answer.questionId);
      let data = {
        questionAnswer: answer
      };

      _ajax.default.post(url, data).then(() => {
        this.advanceQuestionPage(questions[current + 1]);
      }, err => {
        this.send('error', err);
      });
    }

    advanceQuestionPage(next) {
      if (Ember.isNone(next)) {
        let pageNav = this.modelFor('apply').pageNavs.find(x => x.type === _enum.PageType.Jist);
        this.send('advance', pageNav);
      } else {
        this.replaceWith('apply.survey.question', next);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "user", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "advanceQuestion", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "advanceQuestion"), _class.prototype)), _class));
  _exports.default = ApplySurveyRoute;
});