define("applicant-cli/routes/video", ["exports", "applicant-cli/utils/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class VideoRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        pwd: {}
      });
    }

    model(params) {
      return _ajax.default.get("videos/".concat(params.ajoId, "/rooms?pwd=").concat(params.pwd));
    }

    setupController(controller) {
      super.setupController(...arguments);
      let {
        pwd
      } = this.paramsFor('video');
      controller.set('pwd', pwd);
    }

  }

  _exports.default = VideoRoute;
});