define("applicant-cli/routes/preview-job", ["exports", "applicant-cli/utils/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PreviewJobRoute extends Ember.Route {
    titleToken(model) {
      return "Preview for ".concat(model.jobName);
    }

    model(params) {
      return _ajax.default.get("jobPreviews/".concat(params.id));
    }

  }

  _exports.default = PreviewJobRoute;
});