define("applicant-cli/components/x-googlemaps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <iframe src="https://maps.google.com/maps?q={{@address}}&output=embed" title="Map" allowfullscreen />
  
  */
  {"id":"kr54gJf8","block":"{\"symbols\":[\"@address\"],\"statements\":[[10,\"iframe\"],[15,\"src\",[31,[\"https://maps.google.com/maps?q=\",[32,1],\"&output=embed\"]]],[14,\"title\",\"Map\"],[14,\"allowfullscreen\",\"\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"applicant-cli/components/x-googlemaps.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['google-maps']
  }));

  _exports.default = _default;
});