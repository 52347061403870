define("applicant-cli/templates/apply/work-history/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JEkx2MhJ",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"v-work-history\",[],[[\"@model\",\"@keyboardActivated\",\"@advance\",\"@edit\"],[[32,1],true,[30,[36,0],[\"advance\"],null],[30,[36,0],[\"edit\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\"]}",
    "meta": {
      "moduleName": "applicant-cli/templates/apply/work-history/index.hbs"
    }
  });

  _exports.default = _default;
});