define("applicant-cli/routes/account-incomplete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const PAGE = {
    pages: {
      id: 'emailSms',
      name: 'Account Recovery & Notification',
      type: 'Standard',
      elements: [{
        id: 'n6mJt3Ev1Uap81olb7EXhw',
        classes: 'col-xs-12',
        label: 'Account Recovery & Notification',
        row: 0,
        type: 3,
        parentExpressions: []
      }, {
        id: 'XL07A2EqQUKIGVBz1DIvMw',
        classes: 'col-xs-12 alert alert-info',
        label: 'Please enter the information below so that we can send you electronic communications.',
        row: 1,
        type: 0,
        parentExpressions: []
      }, {
        id: 'Q7S2DoknkkGxsE2kOfw9jw',
        classes: 'col-xs-12 col-sm-4 col-sm-push-4',
        label: 'Cell Phone',
        inputMask: '(200) 000-0000',
        maxLength: 15,
        field: 'sms',
        row: 3,
        required: false,
        type: 1,
        parentExpressions: ['{canSms} === undefined']
      }]
    }
  };
  let AccountIncompleteRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = (_temp = class AccountIncompleteRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "titleToken", 'Account Recovery & Notification');

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "user", _descriptor2, this);
    }

    setupController(controller) {
      super.setupController(...arguments);

      if (Ember.isEmpty(this.user.email)) {
        let email = {
          id: 'rd4dGnJMsU6t5Ppxo4rMPg',
          classes: 'col-xs-12 col-sm-4 col-sm-push-4',
          label: 'Email',
          maxLength: 50,
          field: 'email',
          row: 2,
          required: true,
          validExp: 'return /^(([^<>()\\[\\]\\\\.,;:\\s@\']+(\\.[^<>()\\[\\]\\\\.,;:\\s@\']+)*)|(\'.+\'))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$/.test(s);',
          validMsg: 'Please enter a valid email.',
          type: 1
        };
        PAGE.pages.elements.splice(2, 0, email);
      }

      this.store.pushPayload(PAGE); // Setup data source for element proxy

      let model = this.store.peekRecord('page', 'emailSms');
      model.elements.invoke('setup', this.user);
      model.elements.setEach('error', null);
      controller.set('model', model);
    }

    advance() {
      let {
        transition
      } = this.session;

      if (transition) {
        transition.retry();
      } else {
        this.transitionTo('index');
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "advance", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "advance"), _class.prototype)), _class));
  _exports.default = AccountIncompleteRoute;
});