define("applicant-cli/templates/indeed-finish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CL7Zm8pO",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"fail\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"row text-center\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"//cdn.healthcaresource.com/assets/images/indeed/fail.png\"],[14,\"alt\",\"Fail\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-xs-12 col-sm-10 col-sm-offset-1\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      It is taking longer than normal for your information to arrive from Indeed.\\n      We will send you an email notification once your information is received.\\n      Thank you for your interest in job opportunities with us.\\n    \"],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-default btn-block\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"reload\"],null],[12],[2,\"\\n      Check For My Info Again\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[10,\"img\"],[14,\"src\",\"//cdn.healthcaresource.com/assets/images/indeed/apply.gif\"],[14,\"alt\",\"Apply\"],[12],[13],[13],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"One moment please while we populate your information from Indeed\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "meta": {
      "moduleName": "applicant-cli/templates/indeed-finish.hbs"
    }
  });

  _exports.default = _default;
});