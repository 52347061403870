define("applicant-cli/controllers/register", ["exports", "applicant-cli/utils/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    postingId: null,
    email: null,
    password: null,
    error: null,
    emailValid: false,
    passwordValid: false,
    isInternal: false,
    isLoading: false,
    valEmail: true,
    job: Ember.inject.service(),
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    appController: Ember.inject.controller('application'),
    isValid: Ember.computed.and('emailValid', 'passwordValid'),
    hasAccount: Ember.computed.equal('error', 'Account exists.'),
    disable: Ember.computed('isInternal', 'model.{isOpenExternal,isOpenInternal}', 'user.hasInternalApplicants', function () {
      return this.user.hasInternalApplicants && (this.isInternal && !this.model.isOpenInternal || !this.isInternal && !this.model.isOpenExternal);
    }),
    actions: {
      create() {
        if (!this.isValid) {
          return;
        }

        this.set('isLoading', true);
        let credential = {
          username: this.email,
          password: this.password,
          referrer: this.user.referrer,
          isInternal: this.isInternal
        };

        _ajax.default.post("register/".concat(this.postingId), {
          credential
        }).then(res => {
          if (res.error) {
            this.set('error', res.error);
          } else {
            this.session.setToken(res.token);
            this.user.setProperties({
              applicantId: res.appId,
              isInternal: this.isInternal
            });
            this.appController.set('internal', this.isInternal);
            this.job.apply(this.postingId);
          }
        }, err => this.send('error', err)).finally(() => this.set('isLoading', false));
      },

      validate(field, valid) {
        this.set('error', null);
        this.set("".concat(field, "Valid"), valid);
      }

    }
  });

  _exports.default = _default;
});