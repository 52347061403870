define("applicant-cli/helpers/local-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LOCALE = 'en-US';

  function localDate(date) {
    if (date) {
      return new Date(date).toLocaleDateString(LOCALE);
    }
  }

  var _default = Ember.Helper.helper(localDate);

  _exports.default = _default;
});