define("applicant-cli/services/pendo", ["exports", "applicant-cli/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const PENDO_PREFIX = "HHSApplicant_".concat(_environment.default.environment.toUpperCase());
  let PendoService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = (_temp = class PendoService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "user", _descriptor2, this);
    }

    boot() {
      let {
        Pendo = {}
      } = _environment.default;

      if (!window.pendo && Pendo.apiKey) {
        new Ember.RSVP.Promise(resolve => {
          let newScript = document.createElement('script');
          newScript.src = "//cdn.pendo.io/agent/static/".concat(Pendo.apiKey, "/pendo.js");
          newScript.onload = resolve;
          document.head.appendChild(newScript);
        }).then(() => {
          let {
            pendo
          } = window;
          pendo && pendo.initialize(this.getPendoMetadata());
        });
      }
    }

    getPendoMetadata() {
      return {
        account: {
          id: "".concat(PENDO_PREFIX, "_").concat(this.user.clientId),
          name: this.user.app.companyName
        },
        visitor: this.session.isLoggedIn ? {
          id: this.getPendoVisitorId()
        } : null
      };
    }

    getPendoVisitorId() {
      let {
        pendo
      } = window;

      if (this.session.isLoggedIn && this.user.clientId && this.user.applicantId) {
        return "".concat(PENDO_PREFIX, "_").concat(this.user.clientId, "_").concat(this.user.applicantId);
      }

      return pendo.getVisitorId();
    }

    update() {
      let {
        pendo
      } = window;

      if (pendo && pendo.getVisitorId() !== this.getPendoVisitorId()) {
        pendo.updateOptions(this.getPendoMetadata());
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PendoService;
});