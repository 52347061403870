define("applicant-cli/utils/element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = prepareElements;

  function prepareElements(elements, store) {
    let {
      companyName,
      submitDate
    } = store;
    elements.forEach(element => {
      // `submitDate` is virtual
      if (element.field === 'submitDate') {
        let value = submitDate && submitDate.toString();
        element.set('value', value);
      } // Labels may contain replaceable fields


      if (element.type === 0) {
        let label = element.label.replace(/{CompanyName}/gi, companyName);

        if (submitDate) {
          label = label.replace(/{SubmitDate}/gi, submitDate.toLocaleString());
        }

        element.set('label', label);
      }
    });
  }
});