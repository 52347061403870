define("applicant-cli/helpers/parse-string-to-yes-or-no", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function parseStringToYesOrNo(fieldValue) {
    if (fieldValue) {
      let value = fieldValue.toString().toLowerCase();

      if (value === "true") {
        return "Yes";
      } else if (value === "false") {
        return "No";
      }

      return fieldValue;
    }
  }

  var _default = Ember.Helper.helper(parseStringToYesOrNo);

  _exports.default = _default;
});