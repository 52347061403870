define("applicant-cli/app", ["exports", "ember-resolver", "ember-load-initializers", "applicant-cli/config/environment"], function (_exports, _emberResolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const MOBILE = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|kindle|silk/i; // Add branding if needed (`isMobile` is used for client logo)

  window.isMobile = window.innerWidth < 1025 || MOBILE.test(navigator.userAgent.toLowerCase());
  let hasToken = localStorage.getItem('applicant:token') || sessionStorage.getItem('applicant:token');

  if (!window.isMobile && !hasToken || window.V.allowMobileStylesheet) {
    let element = document.querySelector('.container');
    element.parentNode.removeChild(element);
    window.V.brand();
  }

  class App extends Ember.Application {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "customEvents", {
        touchmove: null,
        touchstart: null
      });

      _defineProperty(this, "modulePrefix", _environment.default.modulePrefix);

      _defineProperty(this, "podModulePrefix", _environment.default.podModulePrefix);

      _defineProperty(this, "Resolver", _emberResolver.default);
    }

  }

  _exports.default = App;
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
});