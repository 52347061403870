define("applicant-cli/routes/onboarding/form", ["exports", "applicant-cli/utils/enum"], function (_exports, _enum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OnboardingFormRoute = (_dec = Ember.inject.service, (_class = (_temp = class OnboardingFormRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);
    }

    titleToken(model) {
      return model.name;
    }

    model(params) {
      return this.modelFor('onboarding').forms.findBy('id', params.formId);
    }

    afterModel(model) {
      // Model missing after unloaded at end and Back clicked
      if (!model) {
        return;
      } // Prevent completing form without having completed its pages


      let pages = model.pages.toArray();

      if (!model.signed && pages.some(x => !x.isComplete)) {
        let [firstPage] = pages;
        this.router.replaceWith('onboarding.page', model.id, firstPage);
      }
    }

    setupController(controller, model) {
      super.setupController(...arguments);
      let onboarding = this.modelFor('onboarding');
      let forms = onboarding.hasMany('forms').ids();
      let isTandC = onboarding.tandc === model.id;
      let meta = {
        isLast: forms[forms.length - 1] === model.id,
        isJobOffer: model.isJobOffer,
        type: isTandC ? _enum.FormType.TandC : _enum.FormType.Onboarding
      };
      controller.set('meta', meta);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OnboardingFormRoute;
});