define("applicant-cli/controllers/apply/hartman/phase", ["exports", "applicant-cli/utils/ajax", "applicant-cli/utils/scrollTo"], function (_exports, _ajax, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isLoading: false,
    phase1: Ember.A(),
    phase2: Ember.A(),
    phase1Valid: false,
    phase2Valid: false,
    curPhase: Ember.computed('model.phase', function () {
      return this.get("phase".concat(this.model.phase));
    }),
    actions: {
      update(phase) {
        this.set("phase".concat(this.model.phase), phase);
      },

      next() {
        this.set('error', null);
        this.set('isLoading', true);
        let part = this.model.phase;
        let phase = this.get("phase".concat(part));
        let phaseValid = "phase".concat(part, "Valid");

        if (Ember.isEmpty(phase) || phase.some(x => !x.id)) {
          // Ensure list has been sorted
          this.set('error', this.model.incomplete);
          Ember.run.next(this, () => (0, _scrollTo.default)('.alert-danger'));
          this.set('isLoading', false);
        } else if (this.get(phaseValid)) {
          if (part === '1') {
            this.set('isLoading', false);
            this.transitionToRoute('apply.hartman.phase', 2);
          } else if (part === '2') {
            this.save();
          }
        } else {
          let data = {
            ids: phase.mapBy('id')
          };

          _ajax.default.post('hartmen/valid', data).then(res => {
            this.set(phaseValid, true);
            this.set('isLoading', false);

            if (res.valid) {
              this.send('next');
            } else {
              this.set('error', this.model.reject);
              Ember.run.next(this, () => (0, _scrollTo.default)('.alert-danger'));
            }
          }, err => this.send('error', err));
        }
      }

    },

    save() {
      let data = {
        phase1: this.phase1.mapBy('id'),
        phase2: this.phase2.mapBy('id')
      };

      _ajax.default.post("hartmen/".concat(this.model.ajoId), data).then(() => {
        // Remove Hartman phrases
        this.store.unloadAll('hartman');
        this.send('advance', this.model.pageNav);
      }, err => this.send('error', err));
    }

  });

  _exports.default = _default;
});