define("applicant-cli/controllers/job-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    child: Ember.inject.controller('job-search.index'),
    filterObjects: null,
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    categoryId: Ember.computed('child.category', function () {
      return Number(this.child.category) || null;
    }),
    availableJobs: Ember.computed('allJobs.[]', 'internal', 'user.{isEmployee,isTerminated}', function () {
      if (!this.allJobs) {
        return [];
      }

      return this.user.isEmployee && !this.user.isTerminated || this.internal ? this.allJobs.filter(x => x.openInternal) : this.allJobs.filter(x => x.openExternal);
    }),
    jobs: Ember.computed('availableJobs.[]', 'categoryId', 'child.category', function () {
      return this.child.category === null ? this.availableJobs.map(x => x.name) : this.availableJobs.filter(x => x.categoryId === this.categoryId).map(x => x.name);
    }),
    leftPanel: Ember.computed.or('hasLocations', 'showOpenings', 'categories.length', 'filterObjects.length'),

    init() {
      this._super(...arguments);

      this.set('filterObjects', Ember.A());
    },

    actions: {
      selectCategory(category) {
        this.child.setProperties({
          category: category || null,
          job: null,
          page: 1
        });
      },

      selectJob(job, _, jobSelected) {
        this.child.setProperties({
          job,
          jobSelected,
          page: 1
        });
      },

      selectLocation(location) {
        this.child.setProperties({
          distance: location ? this.distance : -1,
          location: location || null,
          orgId: null,
          page: 1
        });
      },

      updateFilters(newFilters) {
        this.child.setProperties({
          filters: newFilters,
          page: 1
        });
      }

    }
  });

  _exports.default = _default;
});