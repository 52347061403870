define("applicant-cli/services/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    submitDate: Ember.computed.readOnly('app.submitDate'),

    setup(fields) {
      let snapshot = JSON.parse(JSON.stringify(fields));
      Ember.A(snapshot.workHistory);
      Ember.A(fields.workHistory);
      this.set('snapshot', snapshot);
      this.setProperties(fields);
    }

  });

  _exports.default = _default;
});