define("applicant-cli/templates/preview-job", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fhJAuXLn",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"job-posting\"],[12],[2,\"\\n  \"],[10,\"h1\"],[14,0,\"text-center\"],[12],[2,\"\\n    \"],[1,[32,1,[\"jobPreviews\",\"jobName\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[2,\"  \"],[2,[32,1,[\"jobPreviews\",\"description\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "applicant-cli/templates/preview-job.hbs"
    }
  });

  _exports.default = _default;
});