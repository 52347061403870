define("applicant-cli/routes/task/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TaskIndexRoute extends Ember.Route {
    beforeModel(transition) {
      transition.send('advance');
    }

  }

  _exports.default = TaskIndexRoute;
});