define("applicant-cli/routes/indeed-finish", ["exports", "applicant-cli/utils/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let IndeedFinishRoute = (_dec = Ember._action, (_class = (_temp = class IndeedFinishRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "titleToken", 'Indeed Finish');
    }

    setupController(controller) {
      super.setupController(...arguments);
      let {
        indeedId
      } = this.paramsFor('indeed-finish');

      _ajax.default.get("indeed/".concat(indeedId)).then(url => window.location.href = url, () => controller.set('fail', true));
    }

    reload() {
      this.controllerFor('indeed-finish').set('fail', false);
      this.refresh();
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "reload", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "reload"), _class.prototype)), _class));
  _exports.default = IndeedFinishRoute;
});