define("applicant-cli/templates/apply/staff-assessment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ig8ibjbE",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"h2\"],[12],[2,\"Staff Assessment\"],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,1,[\"link\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[2,\"\\n    There is an additional assessment available for you to complete.\\n    In order to expedite the review process, please complete the assessment.\\n    Once completed, you'll be redirected back to your account.\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"br\"],[12],[13],[2,\"\\n\\n  \"],[10,\"a\"],[15,6,[32,1,[\"link\"]]],[14,0,\"btn btn-primary btn-block\"],[12],[2,\"\\n    Complete Staff Assessment\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[2,\"\\n    You have already completed this assessment.\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"x-button\",[],[[\"@class\",\"@click\"],[\"btn btn-primary btn-block\",[30,[36,0],[\"next\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Finish\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"route-action\",\"if\"]}",
    "meta": {
      "moduleName": "applicant-cli/templates/apply/staff-assessment.hbs"
    }
  });

  _exports.default = _default;
});