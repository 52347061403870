define("applicant-cli/components/x-video", ["exports", "x-video/components/x-video"], function (_exports, _xVideo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _xVideo.default;
    }
  });
});