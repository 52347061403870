define("applicant-cli/templates/onboarding/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/V8+kl6+",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"h2 text-center\"],[12],[2,\"\\n  \"],[1,[32,1,[\"job\"]]],[2,\" at \"],[1,[32,1,[\"organization\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"\\n  \"],[1,[32,1,[\"startMessage\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"btn btn-link\"]],[[\"@route\",\"@model\"],[\"apply\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Update Application\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[11,\"button\"],[24,0,\"btn btn-primary btn-block\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"advance\"],null],[12],[2,\"\\n  Complete Onboarding\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "applicant-cli/templates/onboarding/index.hbs"
    }
  });

  _exports.default = _default;
});