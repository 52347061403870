define("applicant-cli/components/page-ghost", ["exports", "@glimmer/component", "applicant-cli/utils/ajax", "applicant-cli/utils/page"], function (_exports, _component, _ajax, _page) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <XPage @model={{@model}} @submit={{this.submit}} />
  
  */
  {"id":"8VaGO+1+","block":"{\"symbols\":[\"@model\"],\"statements\":[[8,\"x-page\",[],[[\"@model\",\"@submit\"],[[32,1],[32,0,[\"submit\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"applicant-cli/components/page-ghost.hbs"}});

  let PageGhostComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = (_temp = class PageGhostComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "user", _descriptor, this);
    }

    async submit(model) {
      let fieldValues = (0, _page.extractModel)(model.get('elements')).filter(x => Ember.isPresent(x.value));

      if (this.args.showInternalResetWarning) {
        fieldValues.push({
          field: 'ResetInternalApply',
          value: true
        });
      }

      let url = "ghosts/".concat(this.args.id);

      if (Ember.isPresent(this.user.referrer)) {
        url += "?ref=".concat(this.user.referrer);
      }

      let data = new FormData();
      data.append('fieldValues', JSON.stringify(fieldValues));
      let {
        ghostFile
      } = window.V;

      if (ghostFile) {
        data.append(ghostFile.name, ghostFile);
      }

      let response = await _ajax.default.file(url, data);
      delete window.V.ghostFile; // Undo custom type `file-manager` -> `x-upload`

      model.get('elements').filter(x => x.type === _page.ElementType.FileManager).forEach(x => x.rollbackAttributes());
      this.args.advance(this.args.id, response);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "user", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "submit", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype)), _class));
  _exports.default = PageGhostComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PageGhostComponent);
});