define("applicant-cli/templates/onboarding/unsubmitted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+RDMZPOe",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"h2 text-center\"],[12],[2,\"\\n  \"],[1,[32,1,[\"job\"]]],[2,\" at \"],[1,[32,1,[\"organization\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"\\n  \"],[1,[32,1,[\"startMessage\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"btn btn-primary btn-block\"]],[[\"@route\",\"@model\"],[\"apply\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Continue\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "applicant-cli/templates/onboarding/unsubmitted.hbs"
    }
  });

  _exports.default = _default;
});