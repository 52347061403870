define("applicant-cli/templates/apply/feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QOoIq5Tx",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"feedback\",[],[[\"@ajoId\"],[[32,1,[\"id\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "applicant-cli/templates/apply/feedback.hbs"
    }
  });

  _exports.default = _default;
});