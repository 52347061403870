define("applicant-cli/components/feedback", ["exports", "applicant-cli/utils/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <h2>
    One Last Thing...
    <hr class="no-margin hr-title">
  </h2>
  <br>
  
  <div class="row">
    <div class="col-xs-12 col-sm-10 col-sm-offset-1 text-center">
      <p class="bottom5">
        Thank you for submitting your application!
        <br>
        Please take a moment to provide <strong>optional</strong> feedback on your application experience.
        <br>
        Your individual feedback is not provided to the Employer and does not affect the selection process.
      </p>
  
      <div class="top10">
        Rate your overall application experience.
      </div>
      <VStars class="lg" @rating={{this.rating}} @onRate={{action "onRating"}} />
  
      <p class="top10">Additional comments (optional):</p>
      <XTextarea class="form-control" @maxlength={{5000}} @rows={{5}} @value={{this.comment}} />
    </div>
  </div>
  
  <XButton @class="btn btn-primary btn-block top10" @isLoading={{this.isLoading}} @click={{action "submit"}}>
    {{#if (or this.rating this.comment)}}
      Submit Feedback
    {{else}}
      Finish
    {{/if}}
  </XButton>
  
  {{yield}}
  
  */
  {"id":"3thchH8P","block":"{\"symbols\":[\"&default\"],\"statements\":[[10,\"h2\"],[12],[2,\"\\n  One Last Thing...\\n  \"],[10,\"hr\"],[14,0,\"no-margin hr-title\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"br\"],[12],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-xs-12 col-sm-10 col-sm-offset-1 text-center\"],[12],[2,\"\\n    \"],[10,\"p\"],[14,0,\"bottom5\"],[12],[2,\"\\n      Thank you for submitting your application!\\n      \"],[10,\"br\"],[12],[13],[2,\"\\n      Please take a moment to provide \"],[10,\"strong\"],[12],[2,\"optional\"],[13],[2,\" feedback on your application experience.\\n      \"],[10,\"br\"],[12],[13],[2,\"\\n      Your individual feedback is not provided to the Employer and does not affect the selection process.\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"top10\"],[12],[2,\"\\n      Rate your overall application experience.\\n    \"],[13],[2,\"\\n    \"],[8,\"v-stars\",[[24,0,\"lg\"]],[[\"@rating\",\"@onRate\"],[[32,0,[\"rating\"]],[30,[36,0],[[32,0],\"onRating\"],null]]],null],[2,\"\\n\\n    \"],[10,\"p\"],[14,0,\"top10\"],[12],[2,\"Additional comments (optional):\"],[13],[2,\"\\n    \"],[8,\"x-textarea\",[[24,0,\"form-control\"]],[[\"@maxlength\",\"@rows\",\"@value\"],[5000,5,[32,0,[\"comment\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"x-button\",[],[[\"@class\",\"@isLoading\",\"@click\"],[\"btn btn-primary btn-block top10\",[32,0,[\"isLoading\"]],[30,[36,0],[[32,0],\"submit\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,0,[\"rating\"]],[32,0,[\"comment\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    Submit Feedback\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    Finish\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"or\",\"if\"]}","meta":{"moduleName":"applicant-cli/components/feedback.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    comment: null,
    isLoading: null,
    rating: null,
    actions: {
      onRating(rating) {
        this.set('rating', rating);
      },

      submit() {
        if (!this.rating && Ember.isBlank(this.comment)) {
          this.store.unloadAll('applicationSummary');
          return this.router.replaceWith('index');
        }

        this.set('isLoading', true);
        let feedback = {
          comment: this.comment,
          rating: this.rating
        };

        _ajax.default.post("feedback/".concat(this.ajoId), {
          feedback
        }).finally(() => {
          this.set('isLoading', false);
          this.store.unloadAll('applicationSummary');
          this.router.replaceWith('index');
        });
      }

    }
  }));

  _exports.default = _default;
});