define("applicant-cli/templates/components/v-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rZHdyrxP",
    "block": "{\"symbols\":[\"page\",\"&default\"],\"statements\":[[6,[37,1],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"nav\"],[14,0,\"text-center\"],[12],[2,\"\\n    \"],[10,\"ul\"],[14,0,\"pagination\"],[12],[2,\"\\n      \"],[10,\"li\"],[15,0,[31,[[30,[36,4],[[35,3],\"disabled\"],null]]]],[12],[2,\"\\n        \"],[11,\"a\"],[24,6,\"#\"],[24,\"aria-label\",\"Previous\"],[4,[38,2],[[32,0],\"incrementPage\",-1],null],[12],[2,\"\\n          \"],[10,\"span\"],[14,\"aria-hidden\",\"true\"],[12],[2,\"«\"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[15,0,[31,[[30,[36,1],[[30,[36,0],[[32,1],[32,0,[\"page\"]]],null],\"active\"],null]]]],[12],[2,\"\\n          \"],[11,\"a\"],[24,6,\"#\"],[4,[38,2],[[32,0],\"changePage\",[32,1]],null],[12],[1,[32,1]],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n      \"],[10,\"li\"],[15,0,[31,[[30,[36,4],[[35,8],\"disabled\"],null]]]],[12],[2,\"\\n        \"],[11,\"a\"],[24,6,\"#\"],[24,\"aria-label\",\"Next\"],[4,[38,2],[[32,0],\"incrementPage\",1],null],[12],[2,\"\\n          \"],[10,\"span\"],[14,\"aria-hidden\",\"true\"],[12],[2,\"»\"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,2,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"action\",\"hasPrev\",\"unless\",\"pages\",\"-track-array\",\"each\",\"hasNext\",\"hasPages\"]}",
    "meta": {
      "moduleName": "applicant-cli/templates/components/v-pagination.hbs"
    }
  });

  _exports.default = _default;
});