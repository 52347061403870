define("applicant-cli/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FprQlsPl",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"alert alert-danger h3\"],[12],[2,\"\\n  Whoops! Looks like something went wrong and may need some attention from our staff.\\n  \"],[8,\"link-to\",[[24,0,\"btn btn-link\"]],[[\"@route\"],[\"help\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[14,0,\"h3\"],[12],[2,\"\\n      Click here to ask for help.\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "applicant-cli/templates/error.hbs"
    }
  });

  _exports.default = _default;
});