define("applicant-cli/utils/enum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EmailRegex = _exports.WorkHistoryType = _exports.StatusType = _exports.PageType = _exports.MessageType = _exports.FormType = void 0;
  const FormType = {
    Employee: 0,
    Onboarding: 1,
    TandC: 2
  };
  _exports.FormType = FormType;
  const MessageType = {
    General: 0,
    Email: 1,
    Form: 2,
    Invite: 3
  };
  _exports.MessageType = MessageType;
  const PageType = {
    Standard: 0,
    Employment: 2,
    Agreement: 4,
    Unemployment: 5,
    Submit: 6,
    Onboarding: 7,
    LaborRequest: 8,
    Hartman: 21,
    Jist: 22,
    ResumeImport: 23,
    StaffAssessment: 24,
    Arena: 25,
    CtmSkills: 26
  };
  _exports.PageType = PageType;
  const StatusType = {
    // Incomplete: 0,
    // Available: 1,
    Submitted: 2,
    Completed: 3 // Hired: 4

  };
  _exports.StatusType = StatusType;
  const WorkHistoryType = {
    Employment: 1,
    Unemployment: 2
  };
  _exports.WorkHistoryType = WorkHistoryType;
  const EmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  _exports.EmailRegex = EmailRegex;
});