define("applicant-cli/controllers/job-search/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['category', 'distance', 'filters', 'job', 'jobSelected', 'location', 'openOnly', 'orgId', 'page'],
    category: null,
    distance: -1,
    filters: null,
    internal: false,
    job: null,
    jobSelected: false,
    location: null,
    openOnly: false,
    orgId: null,
    page: 1,
    jobService: Ember.inject.service('job'),
    user: Ember.inject.service(),
    session: Ember.inject.service(),
    showJobShare: false,
    shareLink: null,
    actions: {
      apply(id, onePage) {
        if (this.session.isLoggedIn) {
          this.set('isLoading', true);
          this.jobService.apply(id).finally(() => this.set('isLoading', false));
        } else if (onePage) {
          this.transitionToRoute('ghost', id);
        } else {
          this.transitionToRoute('register', id);
        }
      },

      toggleJobShare(postingId) {
        let link = "".concat(location.origin, "/jobs/").concat(postingId);
        this.set('shareLink', link);
        this.toggleProperty('showJobShare');
      }

    }
  });

  _exports.default = _default;
});