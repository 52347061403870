define("applicant-cli/templates/apply/page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xnf8cLo8",
    "block": "{\"symbols\":[],\"statements\":[[8,\"page-apply\",[],[[\"@model\",\"@meta\",\"@advance\"],[[32,0,[\"model\"]],[32,0,[\"meta\"]],[30,[36,0],[\"advance\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\"]}",
    "meta": {
      "moduleName": "applicant-cli/templates/apply/page.hbs"
    }
  });

  _exports.default = _default;
});