define("applicant-cli/routes/preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PreviewRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "titleToken", 'Preview');
    }

    model(params) {
      return this.store.findRecord('page', params.pageId);
    } // Setup data source for element proxy


    afterModel(model) {
      model.elements.invoke('setup', {});
    }

  }

  _exports.default = PreviewRoute;
});