define("applicant-cli/routes/job-description", ["exports", "applicant-cli/services/analytics"], function (_exports, _analytics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let JobDescriptionRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = (_temp = class JobDescriptionRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "job", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "user", _descriptor4, this);
    }

    title() {
      // https://github.com/emberjs/ember.js/issues/14721
      // let app = this.modelFor('application');
      let app = this.store.peekAll('job-search').firstObject;
      let model = this.modelFor('job-description');
      return "".concat(model.job.organization, " ").concat(app.careerCenter, " | ").concat(model.job.title);
    }

    model(params) {
      params.ref = this.user.referrer;
      return this.store.queryRecord('job-description', params);
    }

    afterModel(model, transition) {
      if (Ember.isEmpty(model.description)) {
        transition.abort();

        if (this.session.isLoggedIn) {
          this.job.apply(model.id);
        } else {
          this.transitionTo('register', model.id);
        }
      } // Add structured job


      let js = document.createElement('script');
      js.type = 'application/ld+json';
      js.innerHTML = this.structureJob(model) || '';
      document.body.appendChild(js);
      this.analytics.run(_analytics.Hooks.JobDescription);
    }

    deactivate() {
      document.querySelector('script[type="application/ld+json"]').remove();
    }

    setupController(controller) {
      super.setupController(...arguments); // https://github.com/emberjs/ember.js/issues/14721
      // let application = this.modelFor('application');

      let application = this.store.peekAll('job-search').firstObject;
      controller.setProperties({
        clientId: application.id,
        showMap: false
      });
    }

    structureJob(model) {
      var _model$job$validThrou;

      // Do not index jobs no longer accepting applicants
      // Do not index jobs from old urls (orgJobId)
      if (Ember.isEmpty(model.job.lastOpening) || !model.isOpenExternal || !model.isPosting) {
        return;
      } // https://github.com/emberjs/ember.js/issues/14721
      // let { companyName, companyUrl } = this.modelFor('application');


      let {
        companyName,
        companyUrl
      } = this.store.peekAll('job-search').firstObject;
      let job = {
        '@context': 'http://schema.org/',
        '@type': 'JobPosting',
        title: model.job.title,
        description: model.description,
        datePosted: model.job.lastOpening.toJSON(),
        validThrough: (_model$job$validThrou = model.job.validThrough) === null || _model$job$validThrou === void 0 ? void 0 : _model$job$validThrou.toJSON(),
        directApply: true,
        geolocationAvailable: model.geolocationAvailable,
        employmentType: model.job.googleEmploymentType,
        hiringOrganization: {
          '@type': 'Organization',
          name: companyName,
          logo: "".concat(location.origin, "/logo/"),
          url: companyUrl,
          sameAs: location.origin
        },
        jobLocation: {
          '@type': 'Place',
          address: {
            '@type': 'PostalAddress',
            streetAddress: model.job.street,
            addressLocality: model.job.city,
            addressRegion: model.job.state,
            postalCode: model.job.zip,
            addressCountry: 'US'
          }
        },
        identifier: {
          '@type': 'PropertyValue',
          name: 'HHS',
          value: model.id
        }
      };

      if (model.job.education) {
        job.educationRequirements = {
          '@type': 'EducationalOccupationalCredential',
          credentialCategory: model.job.education
        };
      }

      if (Ember.isPresent(model.job.experienceYears)) {
        job.experienceRequirements = {
          '@type': 'OccupationalExperienceRequirements',
          monthsOfExperience: model.job.experienceYears * 12
        };
      }

      if (model.job.compensationMax || model.job.compensationMin) {
        let baseSalary = {
          '@type': 'MonetaryAmount',
          currency: 'USD',
          value: {
            '@type': 'QuantitativeValue',
            unitText: model.job.googleSalaryUnit
          }
        };

        if (model.job.compensationMax && model.job.compensationMin) {
          baseSalary.value.maxValue = model.job.compensationMax;
          baseSalary.value.minValue = model.job.compensationMin;
        } else {
          var _model$job$compensati;

          baseSalary.value.value = (_model$job$compensati = model.job.compensationMax) !== null && _model$job$compensati !== void 0 ? _model$job$compensati : model.job.compensationMin;
        }

        job.baseSalary = baseSalary;
      } // replacer ignores model properties that are 'null'


      return JSON.stringify(job, (_, val) => val || undefined);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "job", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = JobDescriptionRoute;
});