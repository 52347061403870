define("applicant-cli/templates/apply/ctm-skills", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BmBzDqiq",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"h2\"],[12],[2,\"Skills Assessments\"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,1,[\"isComplete\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[2,\"You have already completed all the required assessments.\"],[13],[2,\"\\n  \"],[8,\"x-button\",[[24,0,\"btn btn-primary btn-block\"]],[[\"@isLoading\",\"@click\"],[[32,0,[\"isLoading\"]],[30,[36,1],[\"next\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Continue\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[2,\"\\n    There are additional assessments for you to complete in the Workforce Portal.\\n    In order to expedite the review process, please complete all required assessments.\\n  \"],[13],[2,\"\\n  \"],[10,\"br\"],[12],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,1,[\"link\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"a\"],[15,6,[32,1,[\"link\"]]],[14,\"target\",\"_self\"],[14,0,\"btn btn-primary btn-block\"],[12],[2,\"\\n      Complete Assessments\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[2,\"There was an issue preparing your assessment. Please check back later.\"],[13],[2,\"\\n\"],[6,[37,0],[[32,1,[\"errorMessage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"alert alert-warning\"],[14,\"role\",\"alert\"],[12],[2,\"\\n        \"],[1,[32,1,[\"errorMessage\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[8,\"x-button\",[[24,0,\"btn btn-primary btn-block\"]],[[\"@isLoading\",\"@click\"],[[32,0,[\"isLoading\"]],[30,[36,1],[\"next\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Continue\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"route-action\"]}",
    "meta": {
      "moduleName": "applicant-cli/templates/apply/ctm-skills.hbs"
    }
  });

  _exports.default = _default;
});