define("applicant-cli/routes/index", ["exports", "applicant-cli/utils/enum"], function (_exports, _enum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IndexRoute = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = (_temp = class IndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "titleToken", 'Account');

      _initializerDefineProperty(this, "user", _descriptor, this);
    }

    beforeModel() {
      this.store.unloadAll('account');
    }

    model() {
      let {
        applicantId
      } = this.user;
      return this.store.queryRecord('account', {
        candidateId: applicantId
      });
    }

    afterModel(model) {
      let apps = model.applications;

      if (Ember.isEmpty(apps) && Ember.isNone(model.hireDate)) {
        this.transitionTo('job-search');
      } else if (apps.isAny('status', _enum.StatusType.Completed)) {
        this.set('user.app', {
          hideJobSearch: true
        });
      } else if (apps.isAny('isOnboarding')) {
        let {
          id
        } = apps.findBy('isOnboarding');
        this.transitionTo('onboarding', id);
      } else if (Ember.isPresent(model.offerElsewhere)) {
        let url = "".concat(model.offerElsewhere, "/login?offerElsewhere=true");
        this.send('logout', url);
      }

      this.controllerFor('application').set('internal', model.isInternal);

      if (Ember.isPresent(model.hireDate)) {
        this.user.setProperties({
          id: model.id,
          clientId: model.clientId,
          firstName: model.firstName,
          lastName: model.lastName,
          jobName: model.jobName,
          orgName: model.orgName,
          avatar: model.avatar,
          isEmployee: true,
          isTerminated: Ember.isPresent(model.termDate),
          hireDate: model.hireDate
        });
      }
    }

    setupController(controller, model) {
      super.setupController(...arguments);
      controller.set('hideJobSearch', this.user.app.hideJobSearch);

      if (Ember.isPresent(controller.tab)) {
        return;
      }

      if (model.applications.length > 0 || model.invites.length > 0) {
        controller.set('tab', 0);
      } else if (model.tasks.length > 0) {
        controller.set('tab', 1);
      } else if (model.messages.length > 0) {
        controller.set('tab', 2);
      }
    }

    takeAssessment(model) {
      if (model.hartman) {
        return this.transitionTo('apply.hartman.phase', model.id, 1);
      }

      let [surveyId] = model.surveys;
      this.transitionTo('apply.survey', model.id, surveyId);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "user", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "takeAssessment", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "takeAssessment"), _class.prototype)), _class));
  _exports.default = IndexRoute;
});