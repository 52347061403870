define("applicant-cli/templates/tasks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x8jEciw3",
    "block": "{\"symbols\":[\"task\",\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,2]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[[24,0,\"list-group-item\"]],[[\"@route\",\"@model\"],[\"task\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,1,[\"name\"]]],[2,\"\\n      \"],[10,\"span\"],[14,0,\"pull-right\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"signed\"]],\"LL\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"alert alert-warning\"],[14,\"role\",\"alert\"],[12],[2,\"\\n      You don't have any signed forms.\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "applicant-cli/templates/tasks.hbs"
    }
  });

  _exports.default = _default;
});