define("applicant-cli/routes/ghost", ["exports", "applicant-cli/utils/element"], function (_exports, _element) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GhostRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = (_temp = class GhostRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "titleToken", 'Apply');

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "user", _descriptor2, this);
    }

    model(params) {
      return this.store.findRecord('ghost', params.postingId);
    } // Setup data source for element proxy


    afterModel(model) {
      super.afterModel(...arguments);
      let elements = model.get('page.elements'); // Use `file-manager` instead of `x-upload`

      elements.filterBy('type', 29).forEach(x => x.set('type', 30));
      (0, _element.default)(elements, this.user.app);
      elements.invoke('setup', {
        isGhost: true
      });
    }

    setupController(controller) {
      super.setupController(...arguments);
      controller.set('showInternalResetWarning', false);
    }

    advance(postingId, res) {
      if (res.isForbidden) {
        this.transitionTo('index');
      } else if (Ember.isPresent(res.forgotPasswordId)) {
        let qp = {
          queryParams: {
            ajoId: res.ajoId
          }
        };
        this.transitionTo('change-password', res.forgotPasswordId, qp);
      } else if (Ember.isPresent(res.internalFlag)) {
        this.controllerFor('ghost').setProperties({
          showInternalResetWarning: true,
          internalFlag: res.internalFlag,
          companyName: res.companyName
        });
      } else {
        let queryParams = {
          isGhost: true,
          postingId,
          shouldMigrate: res.shouldMigrate === true
        };
        this.transitionTo('login', {
          queryParams
        });
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "advance", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "advance"), _class.prototype)), _class));
  _exports.default = GhostRoute;
});