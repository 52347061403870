define("applicant-cli/services/analytics", ["exports", "applicant-cli/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Hooks = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const Providers = {
    GoogleAnalytics: 0
  };
  const Hooks = {
    JobDescription: 0,
    ApplicationStarted: 1,
    ApplicationCompleted: 2
  };
  _exports.Hooks = Hooks;
  const HealthcareSourceReferrer = 'hcsjobboard';
  const HookDefinitions = [{
    type: Hooks.JobDescription,
    condition: "this.user.referrer === '".concat(HealthcareSourceReferrer, "'"),
    provider: Providers.GoogleAnalytics,
    meta: {
      key: 'UA-100698483-2',
      category: 'SL',
      action: 'ApplicationViewed',
      label: 'ApplicationViewed'
    }
  }, {
    type: Hooks.ApplicationStarted,
    condition: "this.user.app.referrer === '".concat(HealthcareSourceReferrer, "'"),
    provider: Providers.GoogleAnalytics,
    meta: {
      key: 'UA-100698483-2',
      category: 'SL',
      action: 'ApplicationStarted',
      label: 'ApplicationStarted'
    }
  }, {
    type: Hooks.ApplicationCompleted,
    condition: "this.user.app.referrer === '".concat(HealthcareSourceReferrer, "'"),
    provider: Providers.GoogleAnalytics,
    meta: {
      key: 'UA-100698483-2',
      category: 'SL',
      action: 'ApplicationCompleted',
      label: 'ApplicationCompleted'
    }
  }];
  let AnalyticsService = (_dec = Ember.inject.service, (_class = (_temp = class AnalyticsService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "user", _descriptor, this);

      _defineProperty(this, "hooks", HookDefinitions);
    }

    setup(hooks) {
      this.hooks.pushObjects(hooks);
    }

    processHook(hook) {
      if (hook.condition && !eval(hook.condition)) {
        return;
      }

      if (_environment.default.environment !== 'production') {
        // eslint-disable-next-line no-console
        return console.log("Hook ".concat(hook.type, " triggered"));
      }

      if (hook.provider === Providers.GoogleAnalytics) {
        return this.processGoogleAnalytics(hook.meta);
      } else {
        throw Error("Provider ".concat(hook.provider, " not supported"));
      }
    }

    run(hookType) {
      this.hooks.filter(x => x.type === hookType).forEach(x => this.processHook(x));
    }

    processGoogleAnalytics(meta) {
      let load = () => new Ember.RSVP.Promise(resolve => {
        if (window.ga) {
          return resolve(window.ga);
        }

        let js = document.createElement('script');
        js.type = 'text/javascript';
        js.src = 'https://www.google-analytics.com/analytics.js';
        js.async = true;

        js.onload = () => resolve(window.ga);

        document.getElementsByTagName('head').item(0).appendChild(js);
      });

      load().then(ga => {
        ga('create', meta.key, 'auto');
        ga('send', 'event', meta.category, meta.action, meta.label);
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "user", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AnalyticsService;
});