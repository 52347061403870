define("applicant-cli/routes/apply/index", ["exports", "applicant-cli/utils/enum"], function (_exports, _enum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplyIndex extends Ember.Route {
    beforeModel() {
      let [page] = this.modelFor('apply').pageNavs; // https://github.com/emberjs/ember.js/issues/14721
      // Allow current transition to settle

      Ember.run.next(this, () => page.type === _enum.PageType.ResumeImport ? this.replaceWith('apply.resume-import') : this.replaceWith('apply.page', page.id));
    }

  }

  _exports.default = ApplyIndex;
});