define("applicant-cli/controllers/job-description", ["exports", "ember-cli-guid"], function (_exports, _emberCliGuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['preview', 'title'],
    preview: false,
    title: null,
    isLoading: false,
    showMap: false,
    job: Ember.inject.service(),
    user: Ember.inject.service(),
    session: Ember.inject.service(),
    jobTitle: Ember.computed.or('title', 'model.job.title'),
    useCandidateInternal: Ember.computed.and('user.hasInternalApplicants', 'session.isLoggedIn'),
    allowCandidate: Ember.computed('model.{isOpenExternal,isOpenInternal}', 'user.hasInternalApplicants', function () {
      return this.model.isOpenExternal || this.user.hasInternalApplicants && this.model.isOpenInternal;
    }),
    allowCandidateLoggedIn: Ember.computed('model.{isOpenExternal,isOpenInternal}', 'user.isInternal', function () {
      return !this.user.isInternal && this.model.isOpenExternal || this.user.isInternal && this.model.isOpenInternal;
    }),
    useRosterInternal: Ember.computed('user.{isEmployee,isTerminated}', function () {
      return this.user.isEmployee && !this.user.isTerminated;
    }),
    canApply: Ember.computed('allowCandidate', 'allowCandidateLoggedIn', 'model.isOpenInternal', 'useCandidateInternal', 'useRosterInternal', function () {
      return this.useRosterInternal ? this.model.isOpenInternal : this.useCandidateInternal ? this.allowCandidateLoggedIn : this.allowCandidate;
    }),
    indeed: Ember.computed('model.{id,includeAap,job,laborRequest}', 'clientId', 'jobTitle', 'user.referrer', function () {
      let ref = this.user.referrer;

      if (Ember.isBlank(ref) || !/indeed/.test(ref.toLowerCase())) {
        return;
      }

      let questions = '';

      if (this.model.includeAap) {
        let orgName = encodeURIComponent(this.model.get('job.organization'));
        questions = "?aap=1&orgName=".concat(orgName);
      } // Indeed collapses multiple dashes into a single dash. Use expanded Guids


      let finish = "".concat(location.origin, "/indeedFinish/").concat((0, _emberCliGuid.expandGuid)(this.model.id), "/{indeed_apply_id}");
      let post = "?c=".concat((0, _emberCliGuid.expandGuid)(this.clientId), "&ref=").concat(ref);
      return {
        laborRequest: (0, _emberCliGuid.expandGuid)(this.model.laborRequest),
        jobName: this.jobTitle,
        questions,
        finishAppUrl: finish,
        postUrl: post
      };
    }),
    actions: {
      apply(postingId) {
        if (this.session.isLoggedIn) {
          this.set('isLoading', true);
          this.job.apply(postingId).finally(() => this.set('isLoading', false));
        } else if (this.model.get('job.onePage')) {
          this.transitionToRoute('ghost', postingId);
        } else {
          this.transitionToRoute('register', postingId);
        }
      },

      toggleMap() {
        this.toggleProperty('showMap');
      }

    }
  });

  _exports.default = _default;
});