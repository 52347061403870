define("applicant-cli/templates/job-welcome", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UP+VLYuw",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n\"],[2,\"    \"],[2,[32,1,[\"welcome\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"br\"],[12],[13],[2,\"\\n\\n\"],[8,\"link-to\",[[24,0,\"btn btn-primary btn-block\"]],[[\"@route\"],[\"job-search\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"i\"],[14,0,\"fa fa-search\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n  Search Jobs\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "applicant-cli/templates/job-welcome.hbs"
    }
  });

  _exports.default = _default;
});