define("applicant-cli/adapters/forgot-password", ["exports", "applicant-cli/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ForgotPasswordAdapter extends _application.default {
    urlForFindRecord(id, modelName, snapshot) {
      let url = super.urlForFindRecord(...arguments);
      let {
        ajoId
      } = snapshot.adapterOptions.query;

      if (ajoId) {
        url += "?ajoId=".concat(ajoId);
      }

      return url;
    }

  }

  _exports.default = ForgotPasswordAdapter;
});