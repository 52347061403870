define("applicant-cli/templates/help-requested", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s5eDsHtf",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"h2 text-center\"],[12],[2,\"\\n  Thank you for submitting a help request!\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"\\n  Our support team will provide an email response as soon as it can be processed.\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "applicant-cli/templates/help-requested.hbs"
    }
  });

  _exports.default = _default;
});