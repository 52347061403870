define("applicant-cli/routes/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const PAGE = {
    pages: {
      id: 'help',
      name: 'Help Request',
      type: 'Standard',
      elements: [{
        id: 'CH7K2_g9m0qkJST5Fq59Nw',
        classes: 'col-xs-12',
        label: 'Applicant Help Request',
        row: 0,
        type: 3,
        parentExpressions: []
      }, {
        id: 'g_E8pwD3mU6vp9SNkF0bbg',
        classes: 'col-xs-12 col-sm-4',
        label: 'First Name',
        maxLength: 50,
        field: 'firstName',
        row: 2,
        required: true,
        type: 1,
        parentExpressions: []
      }, {
        id: '-xn41_HXFEeIUUHA9uOEhA',
        classes: 'col-xs-12 col-sm-4',
        label: 'Last Name',
        maxLength: 50,
        field: 'lastName',
        row: 2,
        required: true,
        type: 1,
        parentExpressions: []
      }, {
        id: 'gRIGsf0-BEiK55emQKP4Rg',
        classes: 'col-xs-12 col-sm-4',
        label: 'Email',
        maxLength: 50,
        field: 'email',
        row: 2,
        required: true,
        validExp: 'return /^(([^<>()\\[\\]\\\\.,;:\\s@\']+(\\.[^<>()\\[\\]\\\\.,;:\\s@\']+)*)|(\'.+\'))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$/.test(s);',
        validMsg: 'Please enter a valid email.',
        type: 1,
        parentExpressions: []
      }, {
        id: 'eC83jsLbG0Stfo6rSyS_2w',
        classes: 'col-xs-12',
        label: 'What specific job title are you applying for?',
        maxLength: 100,
        field: 'jobAppliedFor',
        row: 4,
        required: true,
        type: 1,
        parentExpressions: []
      }, {
        id: 'ttYy5BlPn06dN4pxGorEhQ',
        classes: 'col-xs-12',
        label: 'What location are you applying at?',
        maxLength: 50,
        field: 'positionLocation',
        row: 5,
        required: false,
        type: 1,
        parentExpressions: []
      }, {
        id: 'dPOHoyr8ukK6rujGYbsMPQ',
        classes: 'col-xs-12',
        label: 'Have you started an application?',
        field: 'applicationStarted',
        row: 6,
        required: true,
        type: 0,
        parentExpressions: ['{isLoggedIn} === true']
      }, {
        id: 'dsnOseTuQUq3WFBHhM3RYQ',
        classes: 'col-xs-2 col-sm-1',
        label: 'Yes',
        radioValue: true,
        field: 'applicationStarted',
        row: 7,
        required: true,
        type: 2,
        parentExpressions: ['{isLoggedIn} === true']
      }, {
        id: 'yljk-raL2Uu88qDoD6MJAQ',
        classes: 'col-xs-2 col-sm-1',
        label: 'No',
        radioValue: false,
        field: 'applicationStarted',
        row: 7,
        required: true,
        type: 2,
        parentExpressions: ['{isLoggedIn} === true']
      }, {
        id: 'VOlEA5hP_k-j-LJm9bJrJw',
        classes: 'col-xs-12',
        label: 'How may we assist you?',
        field: 'question',
        comboValue: [{
          'id': 0,
          'name': 'Do not have an account'
        }, {
          'id': 1,
          'name': 'Opening for desired position not found'
        }, {
          'id': 3,
          'name': 'Username / password assistance'
        }, {
          'id': 2,
          'name': 'Other'
        }],
        row: 9,
        type: 4,
        parentExpressions: ['{isLoggedIn} === false']
      }, {
        id: 'QYfL5CSaIU6RNb9IAQC44A',
        classes: 'col-xs-12',
        label: 'How may we assist you?',
        field: 'question',
        comboValue: [{
          'id': 4,
          'name': 'Opening for desired position not found'
        }, {
          'id': 2,
          'name': 'Other'
        }],
        row: 9,
        type: 4,
        parentExpressions: ['{isLoggedIn} === true', '{applicationStarted} === false']
      }, {
        id: 'xChVQCU5R0-asKoB3bCsEA',
        classes: 'col-xs-12',
        label: 'How may we assist you?',
        field: 'question',
        comboValue: [{
          'id': 6,
          'name': 'Do not wish to enter social security number'
        }, {
          'id': 7,
          'name': 'How to apply for additional positions'
        }, {
          'id': 8,
          'name': 'Inquiring on status of application'
        }, {
          'id': 9,
          'name': 'No email addresses for references'
        }, {
          'id': 10,
          'name': 'Trouble completing assessment'
        }, {
          'id': 11,
          'name': 'Trouble completing work history'
        }, {
          'id': 12,
          'name': 'Uploading resume'
        }, {
          'id': 2,
          'name': 'Other'
        }],
        row: 9,
        type: 4,
        parentExpressions: ['{isLoggedIn} === true', '{applicationStarted} === true']
      }, {
        id: 'fSAbokO9J0O3UMtgnTkv6w',
        classes: 'col-xs-12',
        label: "<p>Positions that applications are currently being accepted for are listed on the\n          career site. To refine your search, simply search for a job and/or location using the\n          text boxes to specify a position or location and click <strong>Search</strong>.</p>",
        row: 10,
        type: 0,
        parentExpressions: ['{question} === 4']
      }, {
        id: '8jZS-hAyTECrqzZsf4_f1w',
        classes: 'col-xs-12',
        label: "<p>The work history section requires a specified period of time to be accounted\n          for in the application. Any dates that have a message in red beside them\n          <strong>MUST</strong> be accounted for in order to continue with your application.\n          You are required to enter both times of employment and non-employment during the\n          requested time period.</p><p>To add employment information, select either\n          <strong>Employment</strong> or <strong>Non-employment</strong> and then complete the\n          requested information. When all criteria is met there will be no dates in red, and you\n          will be able to proceed with the application.</p>",
        row: 10,
        type: 0,
        parentExpressions: ['{question} === 11']
      }, {
        id: '42aw52QO00GrbgjRUg-MJw',
        classes: 'col-xs-12',
        label: "<p>We can only confirm whether or not an application has been received.\n          Please contact the employer directly with any additional status concerns.</p>",
        row: 10,
        type: 0,
        parentExpressions: ['{question} === 8']
      }, {
        id: 'pxpNyRj7C0ugL48spjqgIQ',
        classes: 'col-xs-12',
        label: "<p>If you would like to be considered for additional positions now or in the\n          future, please log back into your application and choose <strong>Search Jobs</strong>.\n          You must apply for each position that you would like to be considered for. Using your\n          existing username and password will automatically fill in portions of new applications -\n          making the process shorter to complete.</p><p>Available openings can change without\n          notice to reflect the needs of the facility. We suggest checking back often!</p>",
        row: 10,
        type: 0,
        parentExpressions: ['{question} === 7']
      }, {
        id: '1sxASzVD-kSbmotNRBw0yg',
        classes: 'col-xs-12',
        label: "<p>Uploading documents works best on a computer since many mobile devices do not\n          support PDF or document uploads.</p><p>To upload a resume click <strong>Upload</strong>\n          and browse your computer files to select the desired file. Alternatively, you can drag\n          and drop a document into the box to upload the file.</p><p>If required, you are welcome\n          to upload an empty document to continue your application and come back later to upload\n          your resume.</p>",
        row: 10,
        type: 0,
        parentExpressions: ['{question} === 12']
      }, {
        id: 'vqNlbklMzkGUab13Ol2QsA',
        classes: 'col-xs-12',
        label: "<p>You may enter email addresses for those you wish to provide an email for and\n          enter na@na.com for those which you do not wish to provide email addresses.</p>",
        row: 10,
        type: 0,
        parentExpressions: ['{question} === 9']
      }, {
        id: 'VmjsmF9u1USrGyB5_1VKZw',
        classes: 'col-xs-12',
        label: "<p>We understand and appreciate your concerns about your personal information.\n          We assure you that your data is secure and handled with care. However, if you are not\n          comfortable providing your Social Security Number (SSN) on an application, you are\n          welcome to enter all zeros (000-00-0000) as a placeholder. In the event that you are\n          hired, the employer will request your actual SSN as needed.</p>",
        row: 10,
        type: 0,
        parentExpressions: ['{question} === 6']
      }, {
        id: 'd2Q24n5ICEuS9dmwbNegZg',
        classes: 'col-xs-12',
        label: "<p>We apologize for any difficulties! Let's check some of the most common issues\n          encountered and help you complete this portion of the application process.</p>\n          <p>Make sure you're logged into your account. Double check that you are ranking all 18\n          phrases in the correct order. The list should present the items you consider the most\n          positive first and the items you find most negative last.</p><p>Click on a phrase and\n          then click on a spot in the blue column to place it. Reorder the phrases at any time by\n          clicking a phrase and clicking on any space above or below its current position. Once\n          you have ranked all 18 phrases, click <strong>Continue</strong> to move to the next\n          portion of the assessment.</p><p>If you see a message that you may not have understood\n          the instructions, please review your work. Make sure you have moved all phrases from the\n          column on the left to the column on the right. Go ahead and look over your responses\n          again and make any changes you would like. Once you are satisfied with your responses,\n          click <strong>Continue</strong> to move forward.</p><p>If you continue to experience\n          issues, you may find success with a different browser or device. When using a mobile\n          device, please make sure that the zoom on your screen is set to default.</p>",
        row: 10,
        type: 0,
        parentExpressions: ['{question} === 10']
      }, {
        id: 'NkeQF8dxe0eYkjsU0_0_7A',
        classes: 'col-xs-12',
        label: 'Did this explanation resolve your issue?',
        row: 11,
        type: 0,
        parentExpressions: ['{question} !== 2']
      }, {
        id: 'eqzDcHy-a069z1rKbCT-5Q',
        classes: 'col-xs-2 col-sm-1',
        label: 'Yes',
        field: 'issueResolved',
        radioValue: true,
        row: 12,
        required: true,
        type: 2,
        parentExpressions: ['{question} !== 2']
      }, {
        id: 'pSKNs57R8k6DMbUSWHsBVg',
        classes: 'col-xs-2 col-sm-1',
        label: 'No',
        radioValue: false,
        field: 'issueResolved',
        row: 12,
        required: true,
        type: 2,
        parentExpressions: ['{question} !== 2']
      }, {
        id: 'kDxOebsdqEGzvE2vot6D4Q',
        classes: 'col-xs-12',
        label: 'Please describe how we may assist you.',
        maxLength: 500,
        rows: 4,
        field: 'assistance',
        row: 14,
        required: true,
        type: 5,
        parentExpressions: ['{issueResolved} === false', '{question} !== 2']
      }, {
        id: 'oEGHCOaR_EStzwPbMJQI6A',
        classes: 'col-xs-12',
        label: 'Please describe how we may assist you.',
        maxLength: 500,
        rows: 4,
        field: 'assistance',
        row: 14,
        required: true,
        type: 5,
        parentExpressions: ['{question} === 2']
      }]
    }
  };
  let HelpRoute = (_dec = Ember.inject.service, (_class = (_temp = class HelpRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        option: {
          refreshModel: true
        }
      });

      _defineProperty(this, "titleToken", 'Help');

      _initializerDefineProperty(this, "session", _descriptor, this);
    }

    model() {
      this.store.pushPayload(PAGE);
      return this.store.peekRecord('page', 'help');
    }

    setupController(controller) {
      super.setupController(...arguments);
      let {
        option
      } = this.paramsFor('help');
      controller.setProperties({
        isLoggedIn: this.session.isLoggedIn,
        option: option ? Number(option) : null
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = HelpRoute;
});