define("applicant-cli/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    isMobile
  } = window;

  var _default = Ember.Controller.extend({
    queryParams: ['internal', 'ref', 'utm_source'],
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    internal: false,
    isMobile,
    ref: null,
    utm_source: null,
    hasLogo: Ember.computed.or('session.isLoggedIn', 'isMobile'),
    hideJobSearch: Ember.computed.alias('user.app.hideJobSearch'),
    name: Ember.computed('user.{firstName,lastName}', function () {
      let {
        firstName,
        lastName
      } = this.user;

      if (Ember.isPresent(firstName) && Ember.isPresent(lastName)) {
        return "".concat(firstName, " ").concat(lastName);
      }

      return;
    })
  });

  _exports.default = _default;
});