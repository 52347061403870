define("applicant-cli/services/error", ["exports", "bugsnag", "error-handler/services/error", "applicant-cli/config/environment"], function (_exports, _bugsnag, _error, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _error.default.extend({
    bugsnagClient: null,
    isTest: false,

    init() {
      this._super(...arguments); // These need to be set from here in order to correctly import the Ember ENV


      this.setProperties({
        bugsnagClient: (0, _bugsnag.default)({
          apiKey: _environment.default.Bugsnag.apiKey,
          appVersion: "".concat(window.V ? "".concat(window.V.releaseDate, "-").concat(window.V.version) : _environment.default.APP.version),
          autoCaptureSessions: false,
          releaseStage: _environment.default.Bugsnag.environment,
          endpoints: {
            notify: 'https://aws-api.hcshiring.com/bugsnags/errors',
            sessions: 'https://aws-api.hcshiring.com/bugsnags/sessions'
          }
        }),
        isDev: _environment.default.environment !== 'production'
      });
    }

  });

  _exports.default = _default;
});