define("applicant-cli/components/v-indeed", ["exports", "applicant-cli/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span
    class="indeed-apply-widget"
    data-indeed-apply-phone="required"
    data-indeed-apply-apiToken={{this.apiToken}}
    data-indeed-apply-jobId={{@model.laborRequest}}
    data-indeed-apply-jobTitle={{@model.jobName}}
    data-indeed-apply-finishappurl={{@model.finishAppUrl}}
    data-indeed-apply-questions="{{link-webservice}}/Indeed.aspx{{@model.questions}}"
    data-indeed-apply-postUrl="{{link-webservice}}/Indeed.aspx{{@model.postUrl}}">
  </span>
  
  */
  {"id":"H65MsyjK","block":"{\"symbols\":[\"@model\"],\"statements\":[[10,\"span\"],[14,0,\"indeed-apply-widget\"],[14,\"data-indeed-apply-phone\",\"required\"],[15,\"data-indeed-apply-apiToken\",[32,0,[\"apiToken\"]]],[15,\"data-indeed-apply-jobId\",[32,1,[\"laborRequest\"]]],[15,\"data-indeed-apply-jobTitle\",[32,1,[\"jobName\"]]],[15,\"data-indeed-apply-finishappurl\",[32,1,[\"finishAppUrl\"]]],[15,\"data-indeed-apply-questions\",[31,[[34,0],\"/Indeed.aspx\",[32,1,[\"questions\"]]]]],[15,\"data-indeed-apply-postUrl\",[31,[[34,0],\"/Indeed.aspx\",[32,1,[\"postUrl\"]]]]],[12],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-webservice\"]}","meta":{"moduleName":"applicant-cli/components/v-indeed.hbs"}});

  const {
    $
  } = window;

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, (_temp = class extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "apiToken", _environment.default.APP.indeedApiToken);

      $.getScript(_environment.default.APP.indeedResource);
    }

  }, _temp));

  _exports.default = _default;
});