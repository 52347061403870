define("applicant-cli/components/page-label", ["exports", "x-page/components/x-label"], function (_exports, _xLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _xLabel.default;
    }
  });
});