define("applicant-cli/controllers/forgot-username", ["exports", "applicant-cli/utils/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    email: null,
    error: null,
    success: false,
    isLoading: false,
    validEmail: null,
    noAccount: Ember.computed.equal('error', 'No accounts found for this email address.'),
    actions: {
      recover() {
        if (!this.validEmail) {
          return;
        }

        this.set('isLoading', true);
        let credential = {
          username: this.email
        };

        _ajax.default.post('forgotUsername', {
          credential
        }).then(res => {
          if (res.error) {
            this.set('error', res.error);
          } else {
            this.set('success', true);
          }
        }, err => this.send('error', err)).finally(() => this.set('isLoading', false));
      },

      validate(_, valid) {
        this.set('error', null);
        this.set('validEmail', valid);
      }

    }
  });

  _exports.default = _default;
});