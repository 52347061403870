define("applicant-cli/controllers/apply/arena", ["exports", "applicant-cli/utils/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INTERVAL = 1000;
  const MAX_RETRY = 5;

  var _default = Ember.Controller.extend({
    retryCount: 0,
    MAX_RETRY,

    getLink() {
      _ajax.default.get("applications/".concat(this.model.ajoId, "/arena?isPolling=true")).then(model => {
        if (model.link) {
          this.set('model.link', model.link);
        } else if (this.incrementProperty('retryCount') < MAX_RETRY) {
          this.schedulePoll();
        }
      });
    },

    schedulePoll() {
      Ember.run.later(() => this.getLink(), INTERVAL);
    }

  });

  _exports.default = _default;
});