define("applicant-cli/templates/apply/resume-import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BM+0gOff",
    "block": "{\"symbols\":[],\"statements\":[[8,\"import-resume\",[],[[\"@advance\"],[[30,[36,0],[\"next\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\"]}",
    "meta": {
      "moduleName": "applicant-cli/templates/apply/resume-import.hbs"
    }
  });

  _exports.default = _default;
});