define("applicant-cli/components/page-apply", ["exports", "@glimmer/component", "applicant-cli/utils/ajax", "applicant-cli/utils/page", "applicant-cli/utils/enum", "applicant-cli/services/analytics"], function (_exports, _component, _ajax, _page, _enum, _analytics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <XPage
    @buttonLabel={{this.buttonLabel}}
    @model={{@model}}
    @submit={{this.submit}}
    @validate={{this.validate}}
  />
  
  */
  {"id":"5QAIQ2Us","block":"{\"symbols\":[\"@model\"],\"statements\":[[8,\"x-page\",[],[[\"@buttonLabel\",\"@model\",\"@submit\",\"@validate\"],[[32,0,[\"buttonLabel\"]],[32,1],[32,0,[\"submit\"]],[32,0,[\"validate\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"applicant-cli/components/page-apply.hbs"}});

  const BLACKLIST = ['agreement', 'documents'];
  let PageApplyComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class PageApplyComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "user", _descriptor2, this);
    }

    get buttonLabel() {
      return this.args.meta.type === _enum.PageType.Submit ? 'Submit' : 'Continue';
    }

    get isWorkHistory() {
      let {
        type
      } = this.args.meta;
      return type === _enum.PageType.Employment || type === _enum.PageType.Unemployment;
    }

    async submit(model) {
      let {
        pageNav,
        ...pageMeta
      } = this.args.meta;
      let {
        snapshot
      } = this.user; // Use work history snapshot

      if (this.isWorkHistory) {
        if (pageMeta.recordId) {
          snapshot = snapshot.workHistory.find(x => x.id === pageMeta.recordId);
        } else {
          // New work history record
          snapshot = {};
        }
      }

      let elements = (0, _page.extractModel)(model.elements, snapshot, BLACKLIST);
      let {
        ajoId,
        submitDate
      } = this.user.app;
      let notSubmitted = Ember.isEmpty(submitDate);
      let hasMeta = pageMeta && (pageMeta.hasOnboarding || pageMeta.type === _enum.PageType.Submit || pageMeta.isAfterAvailable && notSubmitted);

      if (notSubmitted && (pageMeta.type === _enum.PageType.Submit || pageMeta.isAfterAvailable)) {
        this.analytics.run(_analytics.Hooks.ApplicationCompleted);
      }

      if (!hasMeta && Ember.isEmpty(elements)) {
        return this.args.advance(pageNav, pageMeta);
      }

      let endpoint = 'applications';

      if (ajoId) {
        endpoint += "?ajoId=".concat(ajoId);
      }

      let response = await _ajax.default.put(endpoint, {
        fieldValues: elements,
        pageMeta
      }); // Commit data to local snapshot

      elements.forEach(x => Ember.set(snapshot, x.field, x.value));
      this.args.advance(pageNav, pageMeta, response);
    }

    validate(elements) {
      (0, _page.validateModel)(elements, this.user.snapshot);

      if (this.isWorkHistory) {
        // Validate Work History
        let {
          fromDate,
          toDate
        } = this.user.workHistory.find(x => x.id === this.args.meta.recordId);

        if (fromDate && toDate && fromDate > toDate) {
          let element = elements.find(x => x.field === 'toDate');
          element.set('error', 'To date cannot be before From date.');
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "submit", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validate", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "validate"), _class.prototype)), _class));
  _exports.default = PageApplyComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PageApplyComponent);
});