define("applicant-cli/components/x-upload", ["exports", "applicant-cli/utils/ajax", "applicant-cli/components/file-manager"], function (_exports, _ajax, _fileManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fileManager.default.extend({
    layout: _fileManager.default,
    user: Ember.inject.service(),
    actions: {
      remove(file) {
        _ajax.default.delete("uploads/".concat(file.id)).then(() => this.remove(file.name));
      }

    },

    upload(file) {
      this.set('isUploading', true);
      let {
        ajoId
      } = this.user.app;
      let data = new FormData();
      data.append(file.name, file);

      _ajax.default.file("uploads/".concat(ajoId), data).then(upload => {
        let document = upload.documents;
        document.canDelete = true;
        this.remove(document.name);
        this.files.unshift(document);
        this.user.set('documents', this.files);
      }, () => this.set('error', 'Could not upload file')).finally(() => this.set('isUploading', false));
    },

    remove(fileName) {
      let files = this.files.filter(x => x.name !== fileName);
      this.set('files', files);
    }

  });

  _exports.default = _default;
});