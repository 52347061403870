define("applicant-cli/components/job-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <DirectLink @link={{@link}} @close={{@close}}>
    <div class="text-center">
      <a href="https://twitter.com/share?url={{@link}}?ref=Twitter" rel="noopener noreferrer" target="_blank" class="btn btn-default">
        <i class="fa fa-twitter" aria-hidden="true" />
      </a>
      <a href="https://www.facebook.com/sharer/sharer.php?u={{@link}}?ref=Facebook" rel="noopener noreferrer" target="_blank" class="btn btn-default">
        <i class="fa fa-facebook" aria-hidden="true" />
      </a>
      <a href="https://www.linkedin.com/shareArticle?mini=true&url={{@link}}?ref=LinkedIn" rel="noopener noreferrer" target="_blank" class="btn btn-default">
        <i class="fa fa-linkedin" aria-hidden="true" />
      </a>
      <a href="mailto:?subject=Job Opportunity&amp;body={{@link}}?ref=Email" rel="noopener noreferrer" target="_blank" class="btn btn-default">
        <i class="fa fa-envelope" aria-hidden="true" />
      </a>
    </div>
    <br>
  </DirectLink>
  
  */
  {"id":"d7d0cRgq","block":"{\"symbols\":[\"@link\",\"@close\"],\"statements\":[[8,\"direct-link\",[],[[\"@link\",\"@close\"],[[32,1],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n    \"],[10,\"a\"],[15,6,[31,[\"https://twitter.com/share?url=\",[32,1],\"?ref=Twitter\"]]],[14,\"rel\",\"noopener noreferrer\"],[14,\"target\",\"_blank\"],[14,0,\"btn btn-default\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-twitter\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"a\"],[15,6,[31,[\"https://www.facebook.com/sharer/sharer.php?u=\",[32,1],\"?ref=Facebook\"]]],[14,\"rel\",\"noopener noreferrer\"],[14,\"target\",\"_blank\"],[14,0,\"btn btn-default\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-facebook\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"a\"],[15,6,[31,[\"https://www.linkedin.com/shareArticle?mini=true&url=\",[32,1],\"?ref=LinkedIn\"]]],[14,\"rel\",\"noopener noreferrer\"],[14,\"target\",\"_blank\"],[14,0,\"btn btn-default\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-linkedin\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"a\"],[15,6,[31,[\"mailto:?subject=Job Opportunity&body=\",[32,1],\"?ref=Email\"]]],[14,\"rel\",\"noopener noreferrer\"],[14,\"target\",\"_blank\"],[14,0,\"btn btn-default\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-envelope\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"applicant-cli/components/job-share.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});