define("applicant-cli/templates/components/x-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1h8DtPO5",
    "block": "{\"symbols\":[\"btn\",\"&default\"],\"statements\":[[8,\"ember-wormhole\",[],[[\"@to\",\"@renderInPlace\"],[\"modal-destination\",[34,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"modal fade \",[34,2]]]],[15,\"data-backdrop\",[31,[[30,[36,4],[[35,3],\"static\",\"true\"],null]]]],[14,\"data-keyboard\",\"false\"],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"modal-dialog \",[34,5]]]],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"modal-content\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"modal-header\"],[12],[2,\"\\n          \"],[11,\"button\"],[24,0,\"close\"],[24,\"aria-hidden\",\"true\"],[24,4,\"button\"],[4,[38,0],[[32,0],[32,0,[\"closeButton\",\"action\"]]],null],[12],[2,\"\\n            ×\\n          \"],[13],[2,\"\\n          \"],[10,\"h4\"],[14,0,\"modal-title\"],[12],[1,[34,6]],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"modal-body\"],[12],[2,\"\\n          \"],[18,2,null],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"modal-footer\"],[12],[2,\"\\n          \"],[10,\"span\"],[14,1,\"modal-custom-button\"],[12],[13],[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,7]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[11,\"button\"],[16,0,[31,[\"btn \",[32,1,[\"classNames\"]]]]],[16,\"disabled\",[32,1,[\"disabled\"]]],[24,4,\"button\"],[4,[38,0],[[32,0],[32,1,[\"action\"]]],null],[12],[2,\"\\n\"],[2,\"              \"],[2,[32,1,[\"label\"]]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"renderInPlace\",\"modalClass\",\"disableBackdrop\",\"if\",\"className\",\"title\",\"buttons\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "applicant-cli/templates/components/x-modal.hbs"
    }
  });

  _exports.default = _default;
});