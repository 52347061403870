define("applicant-cli/components/select-dropdown-group", ["exports", "ember-select/components/select-dropdown-group"], function (_exports, _selectDropdownGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _selectDropdownGroup.default;
    }
  });
});