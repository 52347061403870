define("applicant-cli/controllers/change-password", ["exports", "applicant-cli/utils/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['ajoId', 'isInvite', 'orgJobId', 'postingId'],
    ajoId: null,
    isInvite: false,
    orgJobId: null,
    postingId: null,
    password: null,
    error: null,
    isLoading: false,
    passwordValid: false,
    job: Ember.inject.service(),
    user: Ember.inject.service(),
    session: Ember.inject.service(),
    actions: {
      changePassword() {
        if (!this.passwordValid) {
          return;
        }

        this.set('isLoading', true);
        let credential = {
          username: this.model.username,
          password: this.password
        };
        let url = "changePassword/".concat(this.model.id);

        if (this.ajoId) {
          url += "?ajoId=".concat(this.ajoId);
        }

        _ajax.default.put(url, {
          credential
        }).then(res => {
          if (res.error) {
            this.set('error', res.error);
          } else {
            this.session.setToken(res.token);
            this.user.set('applicantId', res.appId);

            if (this.model.pageId) {
              // This is here to handle the CTM skills assessment page being
              // right after the Contact Information page in the order
              if (this.model.pageId === 'CTM Skills Assessment') {
                this.transitionToRoute('apply.ctm-skills', this.ajoId);
              } else {
                this.transitionToRoute('apply.page', this.ajoId, this.model.pageId);
              }
            } else if (Ember.isNone(this.postingId) && Ember.isNone(this.orgJobId)) {
              this.transitionToRoute('index');
            } else {
              this.job.apply(this.postingId || this.orgJobId);
            }
          }
        }, err => this.send('error', err)).finally(() => this.set('isLoading', false));
      },

      validate(_, valid) {
        this.set('error', null);
        this.set('passwordValid', valid);
      }

    }
  });

  _exports.default = _default;
});