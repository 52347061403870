define("applicant-cli/utils/filters", ["exports", "x-filters/utils/filters"], function (_exports, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_filters).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _filters[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _filters[key];
      }
    });
  });
});