define("applicant-cli/templates/video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lw47hO/b",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"video\",[],[[\"@ajoId\",\"@name\",\"@pwd\",\"@isPasswordInvalid\",\"@isProcessLocked\"],[[32,1,[\"videoRooms\",\"id\"]],[32,1,[\"videoRooms\",\"name\"]],[32,0,[\"pwd\"]],[32,1,[\"videoRooms\",\"isPasswordInvalid\"]],[32,1,[\"videoRooms\",\"isProcessLocked\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "applicant-cli/templates/video.hbs"
    }
  });

  _exports.default = _default;
});