define("applicant-cli/routes/onboarding/end", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OnboardingEndRoute extends Ember.Route {
    setupController(controller, model) {
      controller.set('model', model.endMessage); // Prevent user going back to edit any pages

      this.store.peekAll('onboarding').map(x => x.unloadRecord());
    }

  }

  _exports.default = OnboardingEndRoute;
});