define("applicant-cli/routes/apply/hartman/phase", ["exports", "applicant-cli/utils/enum"], function (_exports, _enum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const Titles = ['Part I: Phrases', 'Part II: Quotes'];
  const Instructions = ["<p>Please read the 18 phrases listed below and reflect on\n   their meanings. Rank them from most positive to most\n   negative by selecting the phrase in the list on the left\n   and choosing a position in the list on the right.</p>\n   <p>There is no time limit. Your rankings should reflect\n   your own feelings, thoughts, decisions, or beliefs.</p>\n   <p>Tip: \"Most positive\" can be interpreted as the highest\n   good, the highest value, most pleasant, or the best.\n   \"Most negative\" can be interpreted as the the most evil,\n   the lowest value, the most unpleasant, or the worst.</p>", "<p>Please read the 18 quotations listed below and reflect on\n   their meanings. Rank them from most agreeable to most\n   disagreeable by selecting the quotation in the list on the\n   left and choosing a position in the list on the right.</p>\n   <p>Most agreeable can be interpreted as the highest\n   value, most pleasant, good.</p><p>Most disagreeable can be\n   interpreted as the lowest value, most unpleasant, evil.</p>"];
  const Incomplete = ["Please rank the rest of the phrases from most positive\n   to most negative before clicking continue.", "Please rank the rest of the quotations from most agreeable\n   to most disagreeable before clicking continue."];
  const Better = ['Positive', 'Agreeable'];
  const Worst = ['Negative', 'Disagreeable'];

  class ApplyHartmanPhaseRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "title", Titles);

      _defineProperty(this, "instructions", Instructions);

      _defineProperty(this, "incomplete", Incomplete);

      _defineProperty(this, "reject", "Your results indicate that you may have\n    interpreted the directions incorrectly. Please\n    re-read the directions and retake the assessment.");

      _defineProperty(this, "better", Better);

      _defineProperty(this, "worst", Worst);
    }

    model(params) {
      let id = params.phaseId;
      let apply = this.modelFor('apply');
      let phrases = this.modelFor('apply.hartman').filter(item => item.id.toString()[0] === id.toString());
      return {
        ajoId: apply.id,
        pageNav: apply.pageNavs.find(x => x.type === _enum.PageType.Hartman),
        phase: id,
        title: this.title[id - 1],
        instruction: this.instructions[id - 1],
        incomplete: this.incomplete[id - 1],
        reject: this.reject,
        hartman: {
          better: this.better[id - 1],
          worst: this.worst[id - 1],
          phrases
        }
      };
    }

    setupController(controller, model) {
      super.setupController(...arguments);

      if (model.phase !== '1' && Ember.isEmpty(controller.phase1)) {
        this.replaceWith('apply.hartman.phase', '1');
      }
    }

  }

  _exports.default = ApplyHartmanPhaseRoute;
});