define("applicant-cli/routes/task/form", ["exports", "applicant-cli/utils/enum"], function (_exports, _enum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TaskFormRoute extends Ember.Route {
    titleToken(model) {
      return model.name;
    }

    model(params) {
      return this.modelFor('task').forms.findBy('id', params.formId);
    }

    setupController(controller, model) {
      super.setupController(...arguments);
      let task = this.modelFor('task');
      let forms = task.hasMany('forms').ids();
      let meta = {
        isLast: forms[forms.length - 1] === model.id,
        taskId: task.id,
        type: _enum.FormType.Employee
      };
      controller.set('meta', meta);
    }

  }

  _exports.default = TaskFormRoute;
});