define("applicant-cli/components/job-distance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <strong>Distance</strong>
  <div class="radio top0">
    <label>
      <input type="radio" name="distance" value="5" checked={{eq this.distance 5}}>
      5 miles
    </label>
  </div>
  <div class="radio">
    <label>
      <input type="radio" name="distance" value="10" checked={{eq this.distance 10}}>
      10 miles
    </label>
  </div>
  <div class="radio">
    <label>
      <input type="radio" name="distance" value="30" checked={{eq this.distance 30}}>
      30 miles
    </label>
  </div>
  <div class="radio">
    <label>
      <input type="radio" name="distance" value="50" checked={{eq this.distance 50}}>
      50 miles
    </label>
  </div>
  <div class="radio">
    <label>
      <input type="radio" name="distance" value="-1" checked={{eq this.distance -1}}>
      Any distance
    </label>
  </div>
  
  */
  {"id":"1ojmtgcl","block":"{\"symbols\":[],\"statements\":[[10,\"strong\"],[12],[2,\"Distance\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"radio top0\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"\\n    \"],[10,\"input\"],[14,3,\"distance\"],[14,2,\"5\"],[15,\"checked\",[30,[36,0],[[32,0,[\"distance\"]],5],null]],[14,4,\"radio\"],[12],[13],[2,\"\\n    5 miles\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"radio\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"\\n    \"],[10,\"input\"],[14,3,\"distance\"],[14,2,\"10\"],[15,\"checked\",[30,[36,0],[[32,0,[\"distance\"]],10],null]],[14,4,\"radio\"],[12],[13],[2,\"\\n    10 miles\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"radio\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"\\n    \"],[10,\"input\"],[14,3,\"distance\"],[14,2,\"30\"],[15,\"checked\",[30,[36,0],[[32,0,[\"distance\"]],30],null]],[14,4,\"radio\"],[12],[13],[2,\"\\n    30 miles\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"radio\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"\\n    \"],[10,\"input\"],[14,3,\"distance\"],[14,2,\"50\"],[15,\"checked\",[30,[36,0],[[32,0,[\"distance\"]],50],null]],[14,4,\"radio\"],[12],[13],[2,\"\\n    50 miles\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"radio\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"\\n    \"],[10,\"input\"],[14,3,\"distance\"],[14,2,\"-1\"],[15,\"checked\",[30,[36,0],[[32,0,[\"distance\"]],-1],null]],[14,4,\"radio\"],[12],[13],[2,\"\\n    Any distance\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\"]}","meta":{"moduleName":"applicant-cli/components/job-distance.hbs"}});

  const geo = navigator.geolocation;

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    distance: null,

    click(event) {
      let {
        value,
        type
      } = event.target;

      if (type !== 'radio') {
        return;
      }

      let distance = JSON.parse(value);

      if (geo && Ember.isBlank(this.location)) {
        event.preventDefault();
        this.locate(distance);
      } else {
        this.setProperties({
          distance,
          location: isFinite(distance) ? this.location : null
        });
      }
    },

    locate(distance) {
      return this.position().then(coords => {
        let {
          latitude,
          longitude
        } = coords;
        let location = isFinite(distance) ? "".concat(latitude.toPrecision(6), ",").concat(longitude.toPrecision(6)) : null;
        this.setProperties({
          distance,
          location
        });
      }) // eslint-disable-next-line no-console
      .catch(() => console.log('Rejected'));
    },

    position() {
      let options = {
        maximumAge: 8 * 60 * 60 * 1000,
        timeout: 3000
      };
      return new Ember.RSVP.Promise((resolve, reject) => {
        return geo.getCurrentPosition(position => resolve(position.coords), reason => reject(reason), options);
      });
    }

  }));

  _exports.default = _default;
});