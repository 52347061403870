define("applicant-cli/router", ["exports", "applicant-cli/config/environment", "applicant-cli/utils/ajax"], function (_exports, _environment, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Router = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = (_temp = class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);

      _initializerDefineProperty(this, "pendo", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);
    }

    init() {
      super.init(...arguments);
      this.on('routeDidChange', () => {
        this.pendo.update();

        if (this.session.isLoggedIn) {
          Ember.run.next(this, () => logHit(this.currentRouteName, this.url));
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "pendo", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Router;
  Router.map(function () {
    // Account
    this.route('account-incomplete');
    this.route('change-password', {
      path: 'forgotPassword/:id'
    });
    this.route('forgot-password');
    this.route('forgot-username');
    this.route('ghost', {
      path: 'ghost/:postingId'
    });
    this.route('login');
    this.route('migrate', {
      path: 'migrate/:postingId'
    });
    this.route('register', {
      path: 'register/:postingId'
    }); // Employee

    this.route('tasks');
    this.route('task', {
      path: '/task/:taskId'
    }, function () {
      this.route('end');
      this.route('form', {
        path: 'form/:formId'
      });
      this.route('page', {
        path: 'form/:formId/page/:pageId'
      });
    }); // Not found

    this.route('four-oh-four', {
      path: '*:'
    }); // Error

    this.route('error'); // Apply

    this.route('apply', {
      path: 'apply/:ajoId'
    }, function () {
      this.route('arena');
      this.route('ctm-skills');
      this.route('feedback');
      this.route('hartman', function () {
        this.route('phase', {
          path: '/:phaseId'
        });
      });
      this.route('page', {
        path: '/page/:pageId'
      });
      this.route('resume-import');
      this.route('staff-assessment');
      this.route('survey', {
        path: '/survey/:interviewId'
      }, function () {
        this.route('question', {
          path: '/question/:questionId'
        });
      });
      this.route('work-history', {
        path: '/workHistory'
      }, function () {
        this.route('edit', {
          path: '/:workHistoryId'
        });
      });
    }); // Help

    this.route('help');
    this.route('help-requested'); // Indeed

    this.route('indeed-finish', {
      path: '/indeedFinish/:postingId/:indeedId'
    }); // Index

    this.route('index', {
      path: '/'
    }); // Invites

    this.route('invite', {
      path: 'invite/:id'
    }); // Jobs

    this.route('job-search', {
      path: '/jobs'
    }, function () {
      this.route('index', {
        path: '/'
      });
    });
    this.route('job-description', {
      path: '/jobs/:id'
    });
    this.route('job-welcome', {
      path: '/welcome'
    }); // Onboarding

    this.route('onboarding', {
      path: '/onboarding/:ajoId'
    }, function () {
      this.route('end');
      this.route('form', {
        path: 'form/:formId'
      });
      this.route('page', {
        path: 'form/:formId/page/:pageId'
      });
      this.route('unsubmitted');
    }); // Preview

    this.route('preview', {
      path: 'preview/:pageId'
    });
    this.route('preview-job', {
      path: '/previewJob/:id'
    });
    this.route('video', {
      path: 'video/:ajoId'
    });
  });

  function logHit(routeName, url) {
    // Do not log development or preview
    let isDev = location.hostname.endsWith('.devs');
    let isPreview = routeName === 'preview' || routeName === 'previewJob';

    if (isPreview || isDev) {
      return;
    }

    let hit = {
      pageName: document.title,
      url
    };
    return _ajax.default.post('activity', hit);
  }
});