define("applicant-cli/services/gtm", ["exports", "applicant-cli/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    V
  } = window;
  let GtmService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = (_temp = class GtmService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "user", _descriptor2, this);
    }

    boot() {
      let {
        Gtm = {}
      } = _environment.default;
      let isQa = location.hostname.endsWith('hcsqa.com');

      if (!window.ga && !isQa && Gtm.apiKey) {
        let {
          applicantId,
          clientId,
          isInternal
        } = this.user;
        let {
          companyName
        } = this.user.app;
        let {
          isKiosk,
          isLoggedIn
        } = this.session;
        let {
          referrer
        } = document.referrer;
        window[Gtm.dataLayer] = [{
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
          applicantId,
          clientId,
          companyName,
          hostname: location.hostname,
          isInternal,
          isKiosk: isKiosk || false,
          isLoggedIn,
          referrer,
          releaseDate: V.releaseDate,
          version: V.version
        }];
        let newScript = document.createElement('script');
        newScript.type = 'text/javascript';
        newScript.async = true;
        newScript.src = "https://www.googletagmanager.com/gtm.js?id=".concat(Gtm.apiKey, "&l=").concat(Gtm.dataLayer);
        document.head.appendChild(newScript);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GtmService;
});