define("applicant-cli/templates/apply/survey/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nDyzI15s",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[12],[2,\"Welcome to the Survey\"],[13],[2,\"\\n\\n\"],[10,\"p\"],[12],[2,\"We'd like to get to know you a little better, and a survey is a great place to start!\"],[13],[2,\"\\n\"],[10,\"p\"],[12],[2,\"This survey contains multiple choice questions. Click on a response to select the answer then click \"],[10,\"strong\"],[12],[2,\"Continue\"],[13],[2,\" to continue.\"],[13],[2,\"\\n\"],[10,\"p\"],[12],[2,\"Some questions will not have a right or a wrong answer. Choose the response that seems the best to you or that you like the most. Other questions give you a chance to show off your knowledge of a particular area or skill set. Look for the right answer on these questions.\"],[13],[2,\"\\n\"],[10,\"p\"],[12],[2,\"Heads up! You cannot return to a question once you have responded! Please make sure you have selected the response you want before clicking \"],[10,\"strong\"],[12],[2,\"Continue\"],[13],[2,\".\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"alert alert-info\"],[14,\"role\",\"alert\"],[12],[2,\"Tip: Advance to the next question by clicking \"],[10,\"strong\"],[12],[2,\"Continue\"],[13],[2,\" or by clicking again on your selected answer.\"],[13],[2,\"\\n\\n\"],[11,\"btn\"],[24,0,\"btn btn-primary btn-block\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"advanceQuestion\"],null],[12],[2,\"Begin\"],[13],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "applicant-cli/templates/apply/survey/index.hbs"
    }
  });

  _exports.default = _default;
});