define("applicant-cli/routes/apply/survey/question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplySurveyQuestionRoute extends Ember.Route {
    model(params) {
      return this.store.findRecord('question', params.questionId);
    }

    afterModel() {
      super.afterModel(...arguments);
      let {
        questionId
      } = this.paramsFor('apply.survey.question');
      this.controllerFor('apply.survey').set('questionId', questionId);
    }

  }

  _exports.default = ApplySurveyQuestionRoute;
});