define("applicant-cli/controllers/forgot-password", ["exports", "applicant-cli/utils/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Methods = {
    Email: 1,
    Sms: 2
  };

  var _default = Ember.Controller.extend({
    email: null,
    error: null,
    confirmedAccount: false,
    complete: false,
    isLoading: false,
    validEmail: null,
    code: null,
    wrongCode: false,
    checkDisabled: Ember.computed('code', 'code.length', function () {
      return !this.code || this.code.length < 6;
    }),
    noAccount: Ember.computed.equal('error', 'Account not found.'),
    actions: {
      restart() {
        this.setProperties({
          email: null,
          error: null,
          confirmedAccount: false,
          complete: false,
          isLoading: false,
          validEmail: null,
          code: null,
          wrongCode: false
        });
      },

      selectMethod(value) {
        this.set('resetType', Number(value));
      },

      checkCode() {
        this.set('isLoading', true);

        _ajax.default.get("checkResetCode?username=".concat(encodeURIComponent(this.email), "&code=").concat(this.code)).then(id => this.transitionToRoute('change-password', id)).catch(() => this.set('wrongCode', true)).finally(() => this.set('isLoading', false));
      },

      reset() {
        this.set('isLoading', true);
        let resetPassword = {
          username: this.email,
          type: this.resetType
        };

        _ajax.default.post('initiatePasswordReset', {
          resetPassword
        }).then(() => this.setProperties({
          complete: this.resetType,
          confirmedAccount: true
        })).finally(() => this.set('isLoading', false));
      },

      getInfo() {
        if (!this.validEmail) {
          return;
        }

        this.set('isLoading', true);
        let credential = {
          username: this.email
        };

        _ajax.default.post('forgotPasswordInfo', {
          credential
        }).then(res => {
          if (res.error) {
            this.set('error', res.error);
          } else {
            if (res.methods === Methods.Email || res.methods === Methods.Sms) {
              this.set('resetType', res.methods);
              this.send('reset');
            } else {
              this.setProperties({
                confirmedAccount: true,
                accountEmail: res.email,
                accountPhone: res.phone
              });
            }
          }
        }, err => this.send('error', err)).finally(() => this.set('isLoading', false));
      },

      validate(_, valid) {
        this.setProperties({
          error: null,
          validEmail: valid
        });
      }

    }
  });

  _exports.default = _default;
});