define("applicant-cli/components/button-loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class="button-loading-spinner">
    {{#if @isLoading}}
      <i class="fa fa-spinner fa-pulse fa-fw">&zwnj;</i>
    {{/if}}
    <span class={{if @isLoading "invisible"}}>
      {{yield}}
    </span>
  </span>
  
  */
  {"id":"7zN10tMW","block":"{\"symbols\":[\"@isLoading\",\"&default\"],\"statements\":[[10,\"span\"],[14,0,\"button-loading-spinner\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fa fa-spinner fa-pulse fa-fw\"],[12],[2,\"‌\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"span\"],[15,0,[30,[36,0],[[32,1],\"invisible\"],null]],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}","meta":{"moduleName":"applicant-cli/components/button-loading-spinner.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});