define("applicant-cli/routes/register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let RegisterRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = (_temp = class RegisterRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "titleToken", 'Register');

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "user", _descriptor2, this);
    }

    beforeModel() {
      if (this.session.isLoggedIn) {
        this.transitionTo('index');
      }
    }

    model(params) {
      if (!this.user.hasInternalApplicants) {
        return;
      }

      let cached = this.store.peekRecord('job-description', params.postingId);
      return cached ? new Ember.RSVP.Promise(resolve => resolve(cached)) : this.store.queryRecord('job-description', {
        id: params.postingId,
        ref: this.user.referrer
      });
    }

    setupController(controller) {
      super.setupController(...arguments);
      let {
        postingId
      } = this.paramsFor('register'); // https://github.com/emberjs/ember.js/issues/14721
      // let model = this.modelFor('application');

      let {
        companyName,
        internalApplyCheckbox,
        internalApplyCertify
      } = this.store.peekAll('job-search').get('firstObject');
      let isInternal = this.controllerFor('application').get('internal');
      controller.setProperties({
        companyName,
        email: null,
        error: null,
        internalApplyCheckbox,
        internalApplyCertify,
        isInternal,
        postingId,
        password: null
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = RegisterRoute;
});