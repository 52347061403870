define("applicant-cli/components/import-resume", ["exports", "applicant-cli/utils/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <h2>Import Resume</h2>
  
  <p>Would you like to prefill the application from your resume?</p>
  
  <br>
  
  <span class="btn btn-primary btn-block btn-file">
    {{#if this.isParsing}}
      <i class="fa fa-refresh fa-spin fa-fw" aria-hidden="true" />
    {{/if}}
  
    Prefill From Resume
  
    {{#if (not (and this.isLoading this.isParsing))}}
      <XFileUpload
        @accept=".doc, .docx, .odt, .pdf, .rtf, .wpd, .wps"
        @maxMB={{5}}
        @multiple={{false}}
        @selected={{action "prefill"}}
      />
    {{/if}}
  </span>
  
  <XButton
    @class="btn btn-default btn-block"
    @isDisabled={{this.isParsing}}
    @isLoading={{this.isLoading}}
    @click={{action "advance"}}
  >
    Continue Manually
  </XButton>
  
  */
  {"id":"+EbZcspf","block":"{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[2,\"Import Resume\"],[13],[2,\"\\n\\n\"],[10,\"p\"],[12],[2,\"Would you like to prefill the application from your resume?\"],[13],[2,\"\\n\\n\"],[10,\"br\"],[12],[13],[2,\"\\n\\n\"],[10,\"span\"],[14,0,\"btn btn-primary btn-block btn-file\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"isParsing\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fa fa-refresh fa-spin fa-fw\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  Prefill From Resume\\n\\n\"],[6,[37,1],[[30,[36,3],[[30,[36,2],[[32,0,[\"isLoading\"]],[32,0,[\"isParsing\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"x-file-upload\",[],[[\"@accept\",\"@maxMB\",\"@multiple\",\"@selected\"],[\".doc, .docx, .odt, .pdf, .rtf, .wpd, .wps\",5,false,[30,[36,0],[[32,0],\"prefill\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[8,\"x-button\",[],[[\"@class\",\"@isDisabled\",\"@isLoading\",\"@click\"],[\"btn btn-default btn-block\",[32,0,[\"isParsing\"]],[32,0,[\"isLoading\"]],[30,[36,0],[[32,0],\"advance\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  Continue Manually\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"and\",\"not\"]}","meta":{"moduleName":"applicant-cli/components/import-resume.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    user: Ember.inject.service(),
    isParsing: false,
    isLoading: false,
    actions: {
      advance() {
        this.set('isLoading', true);
        this.advance();
      },

      prefill(file) {
        this.set('isParsing', true);
        let data = new FormData();
        data.append(file.name, file.data);

        _ajax.default.file('resumes', data).then(fields => {
          this.user.setup(fields);
          this.advance();
        });
      }

    }
  }));

  _exports.default = _default;
});