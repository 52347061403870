define("applicant-cli/templates/components/v-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WKZ6QO7+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,\"align\",\"center\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"loading-gen\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ballcolor ball-1\"],[12],[2,\" \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ballcolor ball-2\"],[12],[2,\" \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ballcolor ball-3\"],[12],[2,\" \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ballcolor ball-4\"],[12],[2,\" \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "applicant-cli/templates/components/v-loading.hbs"
    }
  });

  _exports.default = _default;
});