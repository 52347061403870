define("applicant-cli/routes/onboarding", ["exports", "applicant-cli/utils/ajax", "applicant-cli/utils/scrollPage", "applicant-cli/utils/enum"], function (_exports, _ajax, _scrollPage, _enum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const FAUX_FORM = 'yxoZApDMjE-8-RadHF7lqQ';
  let OnboardingRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = (_temp = class OnboardingRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "user", _descriptor2, this);
    }

    beforeModel(transition) {
      if (!this.session.isLoggedIn) {
        transition.abort();
        this.set('session.transition', transition);
        this.intermediateTransitionTo('login');
      }

      let {
        ajoId
      } = this.paramsFor(this.routeName);
      let app = this.store.peekRecord('application-summary', ajoId);

      if (!app || !app.isOnboarding) {
        this.replaceWith('index');
      } else if (app.status < _enum.StatusType.Submitted) {
        this.replaceWith('onboarding.unsubmitted');
      }
    }

    model(params) {
      return Ember.RSVP.hash({
        fields: _ajax.default.get("fields?candidateId=".concat(this.user.applicantId)),
        onboarding: this.store.findRecord('onboarding', params.ajoId, {
          reload: true
        })
      }).then(promises => {
        let model = promises.onboarding;
        this.user.setup(promises.fields);
        this.set('user.app', {
          ajoId: params.ajoId
        });
        this.set('user.app.companyName', model.organization);
        this.set('user.app.hideJobSearch', true);
        return model;
      });
    }

    afterModel() {
      super.afterModel(...arguments);
      (0, _scrollPage.default)();
    }

    advance() {
      let {
        formId
      } = this.paramsFor('onboarding.form');
      let {
        pageId
      } = this.paramsFor('onboarding.page');
      let {
        forms
      } = this.modelFor('onboarding');

      if (Ember.isNone(formId) && Ember.isPresent(pageId)) {
        formId = this.paramsFor('onboarding.page').formId; // "Sign" page to allow double-checking later

        let page = this.modelFor('onboarding.page');
        page.isComplete = true;
      }

      return this.advancePage(forms, formId, pageId);
    }

    advancePage(forms, curFormId, curPageId) {
      let curForm = forms.findBy('id', curFormId);
      let pages = curForm && curForm.hasMany('pages').ids();

      if (Ember.isPresent(pages) && (curPageId || !curForm.signed)) {
        let pageId = pages[pages.indexOf(curPageId) + 1];

        if (Ember.isPresent(pageId)) {
          return this.transitionTo('onboarding.page', curFormId, pageId);
        }

        if (curFormId !== FAUX_FORM) {
          return this.transitionTo('onboarding.form', curFormId);
        }
      }

      let form = forms.objectAt(forms.indexOf(curForm) + 1);

      if (Ember.isNone(form)) {
        return this.transitionTo('onboarding.end');
      }

      pages = form.hasMany('pages').ids();

      if (Ember.isPresent(pages) && !form.signed) {
        return this.advancePage(forms, form.id);
      }

      this.transitionTo('onboarding.form', form.id);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "advance", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "advance"), _class.prototype)), _class));
  _exports.default = OnboardingRoute;
});