define("applicant-cli/routes/four-oh-four", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FourOhFourRoute extends Ember.Route {
    redirect() {
      this.transitionTo('index');
    }

  }

  _exports.default = FourOhFourRoute;
});