define("applicant-cli/routes/application", ["exports", "applicant-cli/utils/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApplicationRoute = (_dec = Ember.inject.service('error'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = (_temp = class ApplicationRoute extends Ember.Route {
    title(tokens) {
      // https://github.com/emberjs/ember.js/issues/14721
      // let model = this.modelFor('application');
      let model = this.store.peekAll('job-search').get('firstObject');

      if (tokens) {
        tokens.unshift('');
      }

      return "".concat(model.companyName, " ").concat(model.careerCenter).concat(tokens.join(' | '));
    }

    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", {
        internal: {
          refreshModel: true
        }
      });

      _initializerDefineProperty(this, "errorHandler", _descriptor, this);

      _initializerDefineProperty(this, "gtm", _descriptor2, this);

      _initializerDefineProperty(this, "pendo", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "user", _descriptor5, this);

      this.errorHandler.initialize();
    }

    beforeModel() {
      // Prevent users from attempting to load anything via local HTML / MHT
      if (location.protocol === 'file:') {
        location.href = 'about:blank';
        return;
      } // Referrer and internal flag are needed for applying


      let {
        internal,
        ref,
        utm_source
      } = this.paramsFor('application');
      let referrer = utm_source || ref || document.referrer.replace(/.*\/\/([^/]*).*/, '$1');
      this.user.setProperties({
        internalSite: internal,
        referrer
      }); // Eager loading

      this.session;
    }

    model(params) {
      return this.store.queryRecord('job-search', params);
    }

    afterModel(model, transition) {
      super.afterModel(...arguments);
      this.user.setProperties({
        hasInternalApplicants: model.hasInternalApplicants,
        isEmployee: model.isEmployee,
        isInternal: model.isInternal,
        isTerminated: model.isTerminated,
        app: {
          companyName: model.companyName
        },
        clientId: model.id,
        applicantId: model.applicantId
      });
      let params = this.paramsFor('application');

      if (model.hasInternalApplicants && model.isLoggedIn && model.isInternal !== params.internal || (!model.isLoggedIn && !model.hasInternalApplicants || model.isLoggedIn && !model.isInternal) && !model.isEmployee && params.internal) {
        transition.abort();
        let {
          queryParams
        } = transition.to;
        queryParams.internal = model.hasInternalApplicants && model.isInternal || model.isEmployee; // Aborted transition prevents implicit model ids

        let params = [];

        for (let {
          to
        } = transition; to !== null; to = to.parent) {
          Object.keys(to.params).map(x => to.params[x]).map(x => params.unshift(x));
        }

        return this.replaceWith(transition.targetName, ...params, {
          queryParams
        });
      }

      if (!model.isLoggedIn) {
        this.session.clearToken();

        if (transition.targetName === 'index') {
          if (Ember.isEmpty(model.welcome)) {
            return this.replaceWith('job-search');
          } else {
            return this.replaceWith('job-welcome');
          }
        }
      }

      this.pendo.boot();
      this.gtm.boot();
    }

    collapseNav() {
      let element = window.$('#bs-navbar');

      if (element.hasClass('in')) {
        element.collapse('hide');
      }
    }

    error(error, transition) {
      this.errorHandler.handle(error, transition);
    }

    goHome(transition, status) {
      if (status === '500' || status === '428') {
        return this.intermediateTransitionTo('error');
      }

      transition && transition.send ? transition.send('goIndex', true) : this.send('goIndex');
    }

    goIndex(wasTransition) {
      if (!wasTransition) {
        this.send('collapseNav');
      }

      if (this.session.isLoggedIn) {
        this.transitionTo('index');
      } else {
        // https://github.com/emberjs/ember.js/issues/14721
        // let model = this.modelFor('application');
        let model = this.store.peekAll('job-search').get('firstObject');

        if (Ember.isEmpty(model.welcome)) {
          let queryParams = {
            distance: 10,
            job: null,
            jobSelected: false,
            location: null,
            openOnly: false,
            page: 1
          };
          this.replaceWith('job-search', {
            queryParams
          });
        } else {
          this.replaceWith('job-welcome');
        }
      }
    }

    logout(returnUrl) {
      _ajax.default.post('logout').then(() => {
        this.session.clear(); // Flush `user` (singleton service)

        let defaultUrl = this.controllerFor('application').internal ? '/jobs?internal=true' : '/';
        window.location = returnUrl || defaultUrl;
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "errorHandler", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "gtm", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pendo", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "collapseNav", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "collapseNav"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "error", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goHome", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "goHome"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goIndex", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "goIndex"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "logout", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "logout"), _class.prototype)), _class));
  _exports.default = ApplicationRoute;
});