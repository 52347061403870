define("applicant-cli/utils/scrollPage", ["exports", "applicant-cli/utils/scrollTo"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = scrollPage;

  // Scroll to important content
  function scrollPage() {
    let el = window.$('#rootDiv');

    if (scrollY > el.offset().top) {
      (0, _scrollTo.default)(el, {
        duration: 0
      });
    }
  }
});