define("applicant-cli/services/session", ["exports", "applicant-cli/utils/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    $
  } = window;
  const INTERVAL = 2 * 60 * 1000;
  let SessionService = (_dec = Ember.inject.service('ember-user-activity@user-activity'), _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, (_class = (_temp = class SessionService extends Ember.Service {
    get isLoggedIn() {
      return this.token !== null;
    } // Private Safari disables storage


    get hasStorage() {
      try {
        localStorage.setItem('test', 0);
        localStorage.removeItem('test');
        return true;
      } catch (_) {
        return false;
      }
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "userActivity", _descriptor, this);

      _initializerDefineProperty(this, "lastHeartbeat", _descriptor2, this);

      _initializerDefineProperty(this, "isKiosk", _descriptor3, this);

      _initializerDefineProperty(this, "token", _descriptor4, this);

      this.restoreSession();
    }

    checkPulse() {
      let now = Date.now();

      if (now - this.lastHeartbeat > INTERVAL) {
        this.lastHeartbeat = now;
        this.heartbeat();
      }
    }

    clear() {
      this.clearToken();

      if (this.hasStorage) {
        localStorage.clear();
        sessionStorage.clear();
      }
    }

    clearToken() {
      // Safari Private Mode disables storage
      if (this.hasStorage) {
        localStorage.removeItem('applicant:token');
        sessionStorage.removeItem('applicant:token');
      }

      this.token = null;

      if ($.ajaxSettings.headers) {
        delete $.ajaxSettings.headers.Authorization;
      }

      if (this.lastHeartbeat) {
        this.userActivity.off('userActive', this, this.checkPulse);
        this.lastHeartbeat = null;
      }
    }

    heartbeat() {
      _ajax.default.put('heartbeats').then(resp => this.setToken(resp.token, false)).catch(() => location.reload());
    }

    setToken(token, persist) {
      this.clearToken();

      if (Ember.isBlank(token)) {
        return;
      }

      if (this.hasStorage && persist) {
        localStorage.setItem('applicant:token', token);
      } else if (this.hasStorage) {
        sessionStorage.setItem('applicant:token', token);
        this.startHeartbeat();
      }

      this.token = token;
      $.ajaxSetup({
        headers: {
          Authorization: token
        }
      });
      this.dropBranding();
    }

    startHeartbeat() {
      if (this.lastHeartbeat === null) {
        this.lastHeartbeat = Date.now();
        this.userActivity.on('userActive', this, this.checkPulse);
      }
    }

    restoreSession() {
      let {
        isKiosk
      } = window.V;

      if (this.hasStorage) {
        let token = localStorage.getItem('applicant:token');

        if (!Ember.isBlank(token)) {
          this.setToken(token, true);
          return;
        }

        token = sessionStorage.getItem('applicant:token');

        if (!Ember.isBlank(token)) {
          this.setToken(token, false);
        }

        if (isKiosk) {
          localStorage.setItem('applicant:isKiosk', true);
        }

        isKiosk = localStorage.getItem('applicant:isKiosk');
      }

      this.isKiosk = isKiosk;
    }

    dropBranding() {
      let brandingCss = document.querySelector('link[href$="theme/style.css"]');

      if (brandingCss) {
        let rootDiv = document.getElementById('rootDiv');
        rootDiv.removeAttribute('style');
        let html = document.createElement('div');
        html.innerHTML = window.V.branding.unbrandedPage;
        let ember = html.firstChild;
        ember.appendChild(rootDiv);
        let {
          body
        } = document;

        while (body.firstChild) {
          body.removeChild(body.firstChild);
        }

        body.appendChild(ember);
        brandingCss.remove();
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "userActivity", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "lastHeartbeat", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isKiosk", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "token", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class));
  _exports.default = SessionService;
});