define("applicant-cli/templates/ghost", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fNlBKCqB",
    "block": "{\"symbols\":[],\"statements\":[[8,\"page-ghost\",[],[[\"@model\",\"@id\",\"@showInternalResetWarning\",\"@buttonLabel\",\"@advance\"],[[32,0,[\"model\",\"page\"]],[32,0,[\"model\",\"id\"]],[32,0,[\"showInternalResetWarning\"]],[30,[36,1],[[32,0,[\"showInternalResetWarning\"]],\"Reset and Continue\",\"Continue\"],null],[30,[36,2],[\"advance\"],null]]],null],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"showInternalResetWarning\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"alert alert-danger top10\"],[12],[2,\"\\n    Our records indicate you are currently\\n    \"],[1,[30,[36,0],[[32,0,[\"internalFlag\"]],\"not\"],null]],[2,\" employed at \"],[1,[32,0,[\"companyName\"]]],[2,\",\\n    however your application indicates otherwise.\\n\\n    If you continue, your status will be updated and your other applications will\\n    need to be resubmitted.\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"unless\",\"if\",\"route-action\"]}",
    "meta": {
      "moduleName": "applicant-cli/templates/ghost.hbs"
    }
  });

  _exports.default = _default;
});