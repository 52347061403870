define("applicant-cli/controllers/index", ["exports", "applicant-cli/utils/ajax", "x-gauge/utils/progress"], function (_exports, _ajax, _progress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    showAvatarCropper: false,
    imageExt: ['bmp', 'gif', 'jpeg', 'jpg', 'png'],
    user: Ember.inject.service(),
    hire: Ember.computed('model.hireDate', function () {
      return (0, _progress.default)(this.model.hireDate);
    }),
    incompleteTasks: Ember.computed('model.tasks', function () {
      return this.model.tasks.filter(x => x.signed === null);
    }),
    actions: {
      saveAvatar(file) {
        let avatar = {
          file
        };

        _ajax.default.put('avatar', {
          avatar
        }).then(() => {
          this.incrementProperty('user.avatar', 1);
          this.set('showAvatarCropper', false);
        });
      },

      toggleAvatarCropper(file) {
        this.set('avatar', file);
        this.toggleProperty('showAvatarCropper');
      }

    }
  });

  _exports.default = _default;
});