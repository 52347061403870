define("applicant-cli/components/v-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <XSelect
    @model={{this.locations}}
    @value={{@value}}
    @class="es-icon es-icon-location es-large"
    @freeText={{true}}
    @placeholder="Location, City, State or Zip"
    @labelKey="id"
    @valueKey="id"
    @dropdown="select-dropdown-group"
    @onChange={{action "changeLocation"}}
    @onSelect={{@onSelect}}
  />
  
  */
  {"id":"SModY4Ip","block":"{\"symbols\":[\"@value\",\"@onSelect\"],\"statements\":[[8,\"x-select\",[],[[\"@model\",\"@value\",\"@class\",\"@freeText\",\"@placeholder\",\"@labelKey\",\"@valueKey\",\"@dropdown\",\"@onChange\",\"@onSelect\"],[[32,0,[\"locations\"]],[32,1],\"es-icon es-icon-location es-large\",true,\"Location, City, State or Zip\",\"id\",\"id\",\"select-dropdown-group\",[30,[36,0],[[32,0],\"changeLocation\"],null],[32,2]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}","meta":{"moduleName":"applicant-cli/components/v-location.hbs"}});

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagName: '',
    locations: Ember.A(),
    store: Ember.inject.service(),
    actions: {
      changeLocation(query) {
        Ember.run.debounce(this, this.lazyLocation, query, 250);
      }

    },

    lazyLocation(query) {
      if (Ember.isBlank(query) || query.length < 2) {
        this.set('locations', Ember.A());
      } else if (isNaN(query.charAt(1))) {
        this.store.query('location', {
          query
        }).then(locs => {
          let locations = Ember.A();
          let cities = locs.filterBy('isAddress');
          let orgs = locs.filterBy('isAddress', false);

          if (Ember.isPresent(orgs)) {
            locations.push({
              id: 'Organizations'
            });
            locations.pushObjects(orgs.map(x => ({
              id: x.id,
              parentId: 'Organizations'
            })));
          }

          if (Ember.isPresent(cities)) {
            locations.push({
              id: 'Locations'
            });
            locations.pushObjects(cities.map(x => ({
              id: x.id,
              parentId: 'Locations'
            })));
          }

          this.set('locations', locations);
        });
      } else if (isFinite(query) && query.length === 5) {
        this.onSelect(query, true);
      }
    }

  }));

  _exports.default = _default;
});