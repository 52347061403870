define("applicant-cli/routes/apply", ["exports", "applicant-cli/services/analytics", "applicant-cli/utils/enum", "applicant-cli/utils/ajax"], function (_exports, _analytics, _enum, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    $
  } = window;
  const ResumeImport = '4it3viizo0me6qdx0xmAGA';
  let ApplyRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = (_temp = class ApplyRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "user", _descriptor3, this);
    }

    beforeModel(transition) {
      if (!this.session.isLoggedIn) {
        transition.abort();
        this.set('session.transition', transition);
        this.intermediateTransitionTo('login');
      }
    }

    model(params) {
      return Ember.RSVP.hash({
        application: this.store.findRecord('application', params.ajoId),
        fields: _ajax.default.get("fields?candidateId=".concat(this.user.applicantId))
      }).then(promises => {
        this.user.setup(promises.fields);
        let model = promises.application;
        let ajoId = model.id; // Route is shared by application and employee

        if (this.user.id === params.ajoId) {
          ajoId = undefined;
        } // Application dependent but stored in `user` for convenience


        this.set('user.app', {
          ajoId,
          companyName: model.companyName,
          referrer: model.referrer,
          submitDate: model.submitDate,
          hideJobSearch: this.user.app.hideJobSearch
        }); // Inject resume parsing if needed
        // https://github.com/emberjs/ember.js/issues/14721
        // let model = this.modelFor('application');

        let application = this.store.peekAll('job-search').get('firstObject');

        if (application.resumeParse && Ember.isNone(promises.fields.firstName)) {
          let resumeImport = {
            id: ResumeImport,
            icon: 'fa fa-upload',
            name: 'Import Resume',
            type: _enum.PageType.ResumeImport
          };
          model.pageNavs.unshift(resumeImport);
        }

        this.analytics.run(_analytics.Hooks.ApplicationStarted);
        return model;
      });
    }

    afterModel(model, transition) {
      super.afterModel(...arguments);

      if (Ember.isNone(window.aid)) {
        window.aid = command => {
          let apply = this;
          let {
            id
          } = apply.modelFor('apply');
          let {
            version
          } = window.V;
          $.ajax({
            type: 'GET',
            url: "/".concat(version, "/api/applications/").concat(id, "/aid/").concat(command)
          }).then(cmd => new Function(cmd).call(apply));
        };
      } // Mark previous pages as completed


      let {
        name,
        params
      } = transition.to;
      let [, route] = name.split('.');

      for (let pageNav of model.pageNavs) {
        let {
          id,
          type
        } = pageNav;
        let isMatch = params.pageId === id || route === 'index' || route === 'arena' && type === _enum.PageType.Arena || route === 'ctm-skills' && type === _enum.PageType.CtmSkills || route === 'hartman' && type === _enum.PageType.Hartman || route === 'resume-import' && type === _enum.PageType.ResumeImport || route === 'staff-assessment' && type === _enum.PageType.StaffAssessment || route === 'survey' && type === _enum.PageType.Jist || route === 'work-history' && (type === _enum.PageType.Employment || type === _enum.PageType.Unemployment);

        if (isMatch) {
          break;
        }

        Ember.set(pageNav, 'isComplete', true);
      }
    }

    deactivate() {
      // Cleanup model - `pageNav` is unmodeled
      this.store.unloadAll('application');
    }

    advance(curPageNav) {
      let model = this.modelFor('apply');
      Ember.set(curPageNav, 'isComplete', true);
      this.advancePage(model, curPageNav);
    }

    advancePage(model, curPageNav) {
      // Find next page
      let pageIndex = model.pageNavs.findIndex(x => x.id === curPageNav.id);
      let page = model.pageNavs[pageIndex + 1]; // Feedback

      if (Ember.isNone(page) && model.canFeedback) {
        return this.transitionTo('apply.feedback');
      } // Index


      if (Ember.isNone(page)) {
        this.store.unloadAll('applicationSummary');
        return this.transitionTo('index');
      } // Resume Parsing


      if (page.type === _enum.PageType.ResumeImport) {
        return this.transitionTo('apply.resume-import');
      } // Work history


      if (page.type === _enum.PageType.Employment) {
        return this.transitionTo('apply.work-history');
      } // Hartman


      if (page.type === _enum.PageType.Hartman) {
        return this.transitionTo('apply.hartman.phase', 1);
      } // Jist


      if (page.type === _enum.PageType.Jist) {
        return this.transitionTo('apply.survey', page.id);
      } // Staff Assessment


      if (page.type === _enum.PageType.StaffAssessment) {
        return this.transitionTo('apply.staff-assessment');
      } // Arena


      if (page.type === _enum.PageType.Arena) {
        return this.transitionTo('apply.arena');
      }

      if (page.type === _enum.PageType.CtmSkills) {
        return this.transitionTo('apply.ctm-skills');
      }

      this.transitionTo('apply.page', page.id);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "advance", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "advance"), _class.prototype)), _class));
  _exports.default = ApplyRoute;
});