define("applicant-cli/routes/task", ["exports", "applicant-cli/utils/ajax", "applicant-cli/utils/scrollPage"], function (_exports, _ajax, _scrollPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TaskRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = (_temp = class TaskRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "user", _descriptor2, this);
    }

    beforeModel(transition) {
      this.store.peekAll('task').map(x => x.unloadRecord());

      if (!this.session.isLoggedIn) {
        transition.abort();
        this.set('session.transition', transition);
        this.intermediateTransitionTo('login');
      }
    }

    model(params) {
      return Ember.RSVP.hash({
        fields: _ajax.default.get("fields?candidateId=".concat(this.user.applicantId)),
        task: this.store.findRecord('task', params.taskId)
      }).then(promises => {
        this.user.setup(promises.fields);
        return promises.task;
      });
    }

    afterModel(_, transition) {
      let {
        canSms,
        email,
        sms
      } = this.user;

      if (Ember.isBlank(email) || Ember.isBlank(canSms) && Ember.isBlank(sms)) {
        transition.abort();
        this.set('session.transition', transition);
        return this.intermediateTransitionTo('account-incomplete');
      }

      super.afterModel(...arguments);
      (0, _scrollPage.default)();
    }

    advance() {
      let {
        formId
      } = this.paramsFor('task.form');
      let {
        pageId
      } = this.paramsFor('task.page');
      let {
        forms
      } = this.modelFor('task');

      if (Ember.isNone(formId) && Ember.isPresent(pageId)) {
        formId = this.paramsFor('task.page').formId;
      }

      return this.advancePage(forms, formId, pageId);
    }

    advancePage(forms, curFormId, curPageId) {
      let curForm = forms.findBy('id', curFormId);
      let pages = curForm && curForm.hasMany('pages').ids();

      if (Ember.isPresent(pages) && (curPageId || !curForm.signed)) {
        let pageId = pages[pages.indexOf(curPageId) + 1];

        if (Ember.isPresent(pageId)) {
          return this.transitionTo('task.page', curFormId, pageId);
        }

        return this.transitionTo('task.form', curFormId);
      }

      let form = forms.objectAt(forms.indexOf(curForm) + 1);

      if (Ember.isNone(form)) {
        return this.transitionTo('index');
      }

      pages = form.hasMany('pages').ids();

      if (Ember.isPresent(pages) && !form.signed) {
        return this.advancePage(forms, form.id);
      }

      this.transitionTo('task.form', form.id);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "advance", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "advance"), _class.prototype)), _class));
  _exports.default = TaskRoute;
});