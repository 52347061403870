define("applicant-cli/utils/guid", ["exports", "ember-cli-guid/utils/guid"], function (_exports, _guid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_guid).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _guid[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _guid[key];
      }
    });
  });
});