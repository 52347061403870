define("applicant-cli/templates/migrate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cTJoYKRZ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"migrate-account\",[],[[\"@postingId\",\"@internalApplyCheckbox\",\"@internalApplyCertify\",\"@complete\"],[[32,0,[\"postingId\"]],[32,0,[\"internalApplyCheckbox\"]],[32,0,[\"internalApplyCertify\"]],[30,[36,0],[\"complete\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\"]}",
    "meta": {
      "moduleName": "applicant-cli/templates/migrate.hbs"
    }
  });

  _exports.default = _default;
});