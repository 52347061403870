define("applicant-cli/controllers/apply/survey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    questionId: null,
    current: Ember.computed('questionId', 'model.questions.[]', function () {
      let {
        questions
      } = this.model;
      let questionId = this.questionId;

      if (Ember.isEmpty(questions) || Ember.isNone(questionId)) {
        return 0;
      }

      return questions.indexOf(questionId);
    }),
    questionsLeft: Ember.computed('current', 'questionId', 'model.questions.[]', function () {
      if (Ember.isNone(this.questionId)) {
        return null;
      }

      return this.model.questions.length - this.current;
    })
  });

  _exports.default = _default;
});