define("applicant-cli/templates/apply/survey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0qGlrFda",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"questionsLeft\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[1,[32,0,[\"questionsLeft\"]]],[13],[2,\" Questions Remaining\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "applicant-cli/templates/apply/survey.hbs"
    }
  });

  _exports.default = _default;
});